<div class="container mb-5 wrapper-content">
    <app-linker-route [headingName]="headingName" [linkerRouteItems]="linkerRouteItems"></app-linker-route>
    <!-- <div class="right-side" style="width: 100%;">
        <div class="row mt-3">
            <div class="col-md-5">
                <div class="row">
                    <div class="container">
                        <div class="row ">
                            <div class="left-container">
                                <p class="addressName">{{profileData?.firstName}} {{profileData?.lastName}}</p>
                                <p style="margin: 1px;"><span class="addressName1">Address:</span><br>
                                    <strong *ngIf="address" class="text-sty">
                                        {{address}}
                                    </strong>
                                    <ngx-skeleton-loader *ngIf="!address" count="3" [theme]="{ 'border-radius': '0', height: '16px', width : '300px' }">
                                    </ngx-skeleton-loader>
                                </p>

                            </div>
                        

                        </div>
                    </div>

                </div>

            </div>
            <div class="col-md-7">
                <div class="leftt-side">
                    <h1 class=" right-side-text">Order Summary</h1>
                    <div class=" mt-4 card card-data" *ngFor="let item of cartListDetails">
                        <div class="row" *ngIf="item?.productId">
                            <div class="col-lg-3 col-md-12 mb-4 mb-lg-0">
                                <div class="bg-image hover-overlay hover-zoom ripple rounded" data-ripple-color="light">
                                    <img [src]="item?.productId?.thumbnail" class="productImageNew" />
                                    <a>
                                        <div class="mask" style="background-color: rgba(251, 251, 251, 0.2)"></div>
                                    </a>
                                </div>
                            </div>
                            <div class="col-lg-5 col-md-6 mb-4 mb-lg-0">
                                <p style="margin: 1px;margin-top: 5px;">
                                    <strong>{{item?.productId?.productName}}</strong>
                                </p>
                                <p class="mb-0" style="white-space: pre;">Unit price : 
                                    <span [ngClass]="item?.isDealActive ? 'price' : ''">
                                        {{ getPrice((item?.priceSizeDetails.price) |
                                            currency:"ZAR":"R") }}
                                    </span> &nbsp;
                                  
                                    <ng-container *ngIf="item?.isDealActive">
                                        <span class="spl-price">{{getPrice(item?.dealPrice |
                                            currency:"ZAR":"R")}} &nbsp; {{item.dealDiscount}}%
                                            Off</span>
                                    </ng-container>
                                    </p>
                                <p class="mb-1">Value/Size:
                                    {{item?.priceSizeDetails?.value}} {{item?.priceSizeDetails?.unit}}</p>
                                <p class="mb-1">Quantity:
                                    {{item.quantity}}</p>

                                <span>
                                    <em class="fa fa-truck" style="color: #c01e2f;" aria-hidden="true"></em>
                                    <span style="margin-left: 8px;"
                                        *ngIf="item?.productId?.expectedDeliveryDays == '3 to 7 days'">Expected delivery
                                        date : </span>
                                    <span style="margin-left: 8px;"
                                        *ngIf="item?.productId?.expectedDeliveryDays == '3 to 8 days'">Expected delivery
                                        date : </span>
                                    <span style="margin-left: 8px;"
                                        *ngIf="item?.productId?.expectedDeliveryDays == '3 to 9 days'">Expected delivery
                                        date : </span>
                                    <span style="margin-left: 8px;"
                                        *ngIf="item?.productId?.expectedDeliveryDays == '3 to 10 days'">Expected
                                        delivery
                                        date : </span>
                                </span>
                            </div>

                            <div class="col-lg-4 col-md-6 mb-4 mb-lg-0">

                        
                                <p class="text-right new-pr m-0">
                                    <strong> 
                                        <ng-container *ngIf="!item?.isDealActive">
                                            {{getPrice((item?.totalPrice * item.quantity) |
                                                currency:"ZAR":"R")}}
                                        </ng-container>
                                        <ng-container *ngIf="item?.isDealActive">
                                            {{getPrice((item?.dealPrice * item.quantity) |
                                                currency:"ZAR":"R")}}
                                        </ng-container>
                                       
                                    </strong>
                                </p>
                                <span style="position: absolute;bottom: 0;"
                                    *ngIf="item?.productId?.expectedDeliveryDays == '3 to 7 days'">{{getAddedDate(item?.createdAt,7)
                                    | date : 'yyyy-MM-dd' }}</span>
                                <span style="position: absolute;bottom: 0;"
                                    *ngIf="item?.productId?.expectedDeliveryDays == '3 to 8 days'">{{getAddedDate(item?.createdAt,8)
                                    | date : 'yyyy-MM-dd' }}</span>
                                <span style="position: absolute;bottom: 0;"
                                    *ngIf="item?.productId?.expectedDeliveryDays == '3 to 9 days'">{{getAddedDate(item?.createdAt,9)
                                    | date : 'yyyy-MM-dd' }}</span>
                                <span style="position: absolute;bottom: 0;"
                                    *ngIf="item?.productId?.expectedDeliveryDays == '3 to 10 days'">{{getAddedDate(item?.createdAt,10)
                                    | date : 'yyyy-MM-dd' }}</span>
                            </div>
                        </div>
                        <div class="row" *ngIf="!item?.productId">
                            <div class="col-lg-12 col-md-12 mb-4 mb-lg-0">
                                <ngx-skeleton-loader  count="6" [theme]="{ 'border-radius': '0', height: '16px' }">
                                </ngx-skeleton-loader>
                            </div>
                            
                        </div>
                    </div>
                   
                    <div class="row justify-content-between mt-4 text-center" style="align-items: center;">
                        <div class="totalamout">
                            <span class="amnt-total-details">Total</span>
                        </div>
                        <div class="totalamout">
                            <span class="total-price-sum">{{payOutDetail?.totalAmount && getPrice(payOutDetail.totalAmount | currency:"ZAR":"R")}}</span>
                        </div>
                    </div>
                    <div class="row justify-content-between mt-1  text-center" style="align-items: center;">
                        <div class="totalamout">
                            <span class="amnt-total-details">Delivery Fee</span>
                        </div>
                        <div class="totalamout">
                            <span class="total-price-sum">{{payOutDetail?.deliveryFee ? '+' : 'Free'}} 
                                <ng-container *ngIf="payOutDetail?.deliveryFee">
                                {{payOutDetail?.deliveryFee && getPrice( payOutDetail.deliveryFee | currency:"ZAR":"R")}}
                                    
                                </ng-container>
                            </span>
                        </div>
                    </div>
                  
                    
                    <div class="row justify-content-between mt-1 text-center" style="align-items: center;">

                        <div class="totalamout">
                            <span class="amnt-total-details">VAT @15%</span>
                        </div>
                        <div class="totalamout">
                            <span class="total-price-sum">+ {{payOutDetail?.vat && getPrice(payOutDetail.vat |
                                currency:"ZAR":"R")}}</span>
                        </div>
                    </div>
                    <div class="row justify-content-between mt-1 text-center" style="align-items: center;">

                        <div class="totalamout">
                            <span class="amnt-total-details">Total payable amount</span>
                        </div>
                        <div class="totalamout">
                            <span class="total-price-sum new-pr">{{payOutDetail?.totalAmountWithVat && getPrice(payOutDetail?.totalAmountWithVat |
                                currency:"ZAR":"R")}}</span>
                        </div>
                    </div>
                    <div class="row justify-content-between mt-2 text-center" style="align-items: center;">
                        <span class="m-auto" >
                          
                            <button class="butCh" *ngIf="flag" (click)="buyOrder(1)">Continue</button>
                            <button class="butCh op05" *ngIf="!flag">Loading...</button>
                         
                        </span>
           
                    </div>
                </div>


            </div>
        </div>
    </div> -->


    <section id="maincontent" class="page-main container"> <a id="contentarea" tabindex="-1"></a>
        <div class="row">
            <div class="row column main p-0 col-lg-12 col-md-12 col-sm-12 col-xs-12">

                <div class="clr"></div>
                <div class="cart-container col-md-8 p-0 uf_cartleftbox uf_cart_container border">
                    <div class="cart-products-toolbar cart-products-toolbar-top toolbar"
                        data-attribute="cart-products-toolbar-top"></div> <input type="hidden" id="uf_mmtdetailurl"
                        value="https://udesignapi.utsavfashion.com/api/v1/mmt/get.svc/getdetails"><input type="hidden"
                        id="totalshipamt" value="0">
                    <form action="https://www.utsavfashion.com/checkout/cart/updatePost/" method="post"
                        id="form-validate" class="form form-cart" novalidate="novalidate"><input name="form_key"
                            type="hidden" value="iqOHv1Upl97Gkn6q">
                        <div class="cart table-wrapper uf_cartwrapper">
                            <table id="shopping-cart-table" class="cart items data table">
                                <caption role="heading" aria-level="2" class="table-caption">Shopping Cart Items
                                </caption>
                                <thead>
                                    <tr class="d-flex">
                                        
                                        <th class="col item" scope="col"><span>Delivery addresses</span></th>
                                        <th  style="cursor: pointer;" scope="col">
                                            <span class ="amm"(click)="back()">Change Address</span>
                                        </th>
                                        <!-- <th class="col price" scope="col"><span>Unit Price</span></th> -->
                                        <!-- <th class="col subtotal" scope="col"><span>Subtotal</span></th> -->
                                    </tr>
                                </thead>
                                <tbody class="cart item uf_bagitem" data-sku="KMDF127_32" data-productid="3240488">
                                    <tr class="item-info">
                                        <td>
                                            <div class="postion-relative">
                                                <p class="uname">{{profileData?.firstName}} {{profileData?.lastName}}
                                                </p>
                                                <p class="address">
                                                    {{profileData?.addressLine1}} <span
                                                        *ngIf="profileData?.addressLine2">,</span>
                                                    {{profileData?.addressLine2}},
                                                    <!-- {{profileData?.city}},
                                                    {{profileData?.state}},{{profileData?.country}}, -->
                                                    {{profileData?.zipCode}}

                                                </p>
                                               

                                            </div>
                                        </td>
                                    </tr>
                                    <tr class="item-info" *ngFor="let item of cartListDetails;let i = index">

                                        <td data-th="Item" class="col item">
                                            <div _ngcontent-bvo-c149="" class="uf_cartproductimg"><a
                                                    _ngcontent-bvo-c149=""
                                                    title="Embroidered Chanderi Silk Abaya Style Suit in Off White"
                                                    tabindex="-1" class="product-item-photo"><span
                                                        _ngcontent-bvo-c149=""
                                                        class="product-image-container product-image-container-3240488"
                                                        style="width: 295px;"><span _ngcontent-bvo-c149=""
                                                            class="product-image-wrapper"><img _ngcontent-bvo-c149=""
                                                                loading="lazy" width="295" height="427"
                                                                alt="Embroidered Chanderi Silk Abaya Style Suit in Off White"
                                                                class="product-image-photo"
                                                                [src]="item?.productId?.productImage[0]"></span></span></a>
                                            </div>
                                            <div class="product-item-details position-relative">
                                                <div class="product-item-name">

                                                    <p class="productTitle m-0">{{item?.productId?.productName}}</p>


                                                    <div class="price-excluding-tax" data-label="Excl. Tax">
                                                        <span class="cart-price">
                                                            <span class="reg_price" style="margin: 0 12px 0 0;">
                                                                <span [ngClass]="item?.isDealActive ? 'price' : ''">
                                                                    {{getPrice(item?.priceSizeDetails?.price |
                                                                    currency:"ZAR":"R")}}</span>
                                                            </span>
                                                            <span class="spl_price" *ngIf="item?.isDealActive"><span
                                                                    class="price">{{getPrice(item?.dealPrice |
                                                                    currency:"ZAR":"R")}}</span> &nbsp;
                                                                <span class="discount-percent">{{item.dealDiscount}}%
                                                                    Off</span>
                                                            </span>
                                                        </span>
                                                    </div>

                                                </div>

                                                <ul class="item-options uf_bagitemoptions" style="padding: 2px;">
                                                    <li class="uf_bagoptionlist "><span class="uf_bagitemsize">
                                                            Qty
                                                            : <strong>{{item?.quantity}}</strong>
                                                        </span>
                                                    </li>
                                                    <li class="uf_bagoptionlist ">
                                                        <span class="uf_bagitemsize d-flex">Value/Size :
                                                            <div class="size-specification">
                                                                {{item?.priceSizeDetails?.value}}
                                                                {{item?.priceSizeDetails?.unit != 'other'
                                                                ?item?.priceSizeDetails?.unit : '' }}
                                                            </div>

                                                        </span>
                                                    </li>

                                                    <!-- <div class="d-flex">
                                                        <button class="decriment" [disabled]="item?.quantity <= 1"
                                                            (click)="updateCartValue(item?._id,item?.priceSizeDetails, item.quantity - 1,item?.quantity,i,item?.availableQuantity)"><em
                                                                class="fa fa-minus"></em></button>
                                                        <div class="prod-quant">
                                                            <p class="m-0">{{ item?.quantity}}</p>
                                                        </div>
    
                                                        <button class="incriment"
                                                            (click)="updateCartValue(item?._id,item?.priceSizeDetails,item?.quantity + 1,item?.quantity,i,item?.availableQuantity)"><em
                                                                class="fa fa-plus"></em></button>
                                                    </div> -->



                                                </ul>
                                                <!-- <span class="uf_bagoptionlist lmp">
                                                    <a class="uf_bagitemsize" style="    cursor: pointer;text-decoration: underline;
                                                    " (click)="removeCartList(item?._id)">Remove</a>
    
                                                </span> -->
                                                <span class="uf_bagitemsize mnst">Expected
                                                    delivery date : <span style="margin-left: 8px;"
                                                        *ngIf="item?.productId?.expectedDeliveryDays == '3 to 7 days'">
                                                        <span
                                                            *ngIf="item?.productId?.expectedDeliveryDays == '3 to 7 days'">{{getAddedDate(item?.createdAt,7)
                                                            | date : 'y-MM-dd' }}</span></span>
                                                    <span style="margin-left: 8px;"
                                                        *ngIf="item?.productId?.expectedDeliveryDays == '3 to 8 days'">
                                                        <span
                                                            *ngIf="item?.productId?.expectedDeliveryDays == '3 to 8 days'">{{getAddedDate(item?.createdAt,8)
                                                            | date : 'y-MM-dd' }}</span></span>
                                                    <span style="margin-left: 8px;"
                                                        *ngIf="item?.productId?.expectedDeliveryDays == '3 to 9 days'"><span
                                                            *ngIf="item?.productId?.expectedDeliveryDays == '3 to 9 days'">{{getAddedDate(item?.createdAt,9)
                                                            | date : 'y-MM-dd' }}</span></span>
                                                    <span style="margin-left: 8px;"
                                                        *ngIf="item?.productId?.expectedDeliveryDays == '3 to 10 days'"><span
                                                            *ngIf="item?.productId?.expectedDeliveryDays == '3 to 10 days'">{{getAddedDate(item?.createdAt,10)
                                                            | date : 'y-MM-dd' }}</span></span>
                                                    <span style="margin-left: 8px;"
                                                        *ngIf="item?.productId?.expectedDeliveryDays == 'Select Expected Days'"><span
                                                            *ngIf="item?.productId?.expectedDeliveryDays == 'Select Expected Days'">NA</span>

                                                    </span>
                                                </span>
                                            </div>

                                        </td>
                                        <!-- <td class="col price uf_mobhide" data-th="Price">
                                            <span class="price-excluding-tax" data-label="Excl. Tax">
                                                <span class="cart-price">
                                                    <span class="reg_price">
                                                        <span class="price">{{getPrice(getActualPrice(item) |
                                                            currency:"ZAR":"R")}}</span></span><br> <span
                                                        class="spl_price"><span class="price">{{getPrice(item?.totalPrice |
                                                            currency:"ZAR":"R")}}</span><br>
                                                        <span class="discount-percent">({{getDiscount(item)}}% Off)</span>
                                                    </span> </span> </span>
                                        </td> -->
                                        <!-- <td class="col subtotal uf_mobhide" data-th="Subtotal"> <span
                                                class="price-excluding-tax" data-label="Excl. Tax"> <span
                                                    class="cart-price"> <span class="price">{{getPrice((item?.totalPrice * item.quantity) | currency:"ZAR":"R")}}</span></span> </span>
                                        </td> -->
                                    </tr>
                                </tbody>
                            </table>
                        </div>

                    </form>
                    <div class="cart-products-toolbar cart-products-toolbar-top toolbar"
                        data-attribute="cart-products-toolbar-top"></div>
                </div>
                <div id="uf_cartdockable" class="col-md-4 uf_cartdockable">
                    <div class="row shopping-cart-bottom w-100 uf_cartrightbox uf_cartdockable_inner">

                        <div class="uf_cartsummary w-100">
                            <div class="summary title cart_summary">Order Summary
                            </div>
                            <div id="cart-totals" class="cart-totals" data-bind="scope:'block-totals'">
                                <!-- ko template: getTemplate() -->
                                <div class="table-wrapper" data-bind="blockLoader: isLoading">
                                    <table class="data table totals">

                                        <tbody>

                                            <tr class="totals sub">
                                                <th data-bind="i18n: title" scope="row">Subtotal</th>
                                                <td class="amount">
                                                    <span class="price"
                                                        data-bind="text: getValue(), attr: {'data-th': title}"
                                                        data-th="Subtotal">{{payOutDetail &&
                                                        getPrice(payOutDetail.subTotal
                                                        |
                                                        currency:"ZAR":"R")}}</span>
                                                </td>
                                            </tr>

                                            <tr class="totals sub uf_disdetailhead uf_discountcont">
                                                <td colspan="1" class="uf_title" scope="row">
                                                    Discount

                                                </td>
                                                <td class="amount discount_td" data-bind="attr: {'data-th': title}"
                                                    data-th="Discount">
                                                    <span><span class="price" data-bind="text: getValue()">-
                                                            {{payOutDetail && getPrice(payOutDetail.totalDiscount |
                                                            currency:"ZAR":"R")}}</span></span>
                                                </td>
                                            </tr>
                                            <tr class="totals sub uf_disdetailhead uf_discountcont">
                                                <td colspan="1" class="uf_title" scope="row">
                                                    Total

                                                </td>
                                                <td class="amount discount_td" data-bind="attr: {'data-th': title}"
                                                    data-th="Discount">
                                                    <span><span class="price" data-bind="text: getValue()">
                                                            {{payOutDetail && getPrice(payOutDetail.totalAmount |
                                                            currency:"ZAR":"R")}}</span></span>
                                                </td>
                                            </tr>
                                            <tr class="totals sub uf_disdetailhead uf_discountcont">
                                                <td colspan="1" class="uf_title" scope="row">
                                                    VAT(15 %)

                                                </td>
                                                <td class="amount discount_td" data-bind="attr: {'data-th': title}"
                                                    data-th="Discount">
                                                    <span><span class="price" data-bind="text: getValue()">
                                                            {{payOutDetail && getPrice(payOutDetail.vat |
                                                            currency:"ZAR":"R")}}</span></span>
                                                </td>
                                            </tr>
                                            <tr class="totals sub uf_disdetailhead uf_discountcont">
                                                <td colspan="1" class="uf_title" scope="row">
                                                    Shipping & Handling <br>
                                                    ({{payOutDetail && getDeliveryName(payOutDetail?.deliveryMode)}})

                                                </td>
                                                <td class="amount discount_td" data-bind="attr: {'data-th': title}"
                                                    data-th="Discount">
                                                    <span class="price"
                                                        data-bind="html: getShipValueUf(), attr: {'data-th': title}"
                                                        data-th="Shipping &amp; Handling">


                                                        <span class="spl_price"> {{payOutDetail &&
                                                            (payOutDetail.deliveryFee ?'+ '+
                                                            getPrice(payOutDetail.deliveryFee | currency:"ZAR":"R") :
                                                            'Free')}}</span>
                                                    </span>
                                                </td>
                                            </tr>




                                            <tr class="grand totals grand-total redcolor">
                                                <th scope="row">
                                                    <strong data-bind="i18n: title">Grand Total</strong>
                                                </th>
                                                <td data-bind="attr: {'data-th': title}" class="amount"
                                                    data-th="Grand Total">
                                                    <strong><span style="color:rgb(73, 116, 113);" class="price"
                                                            data-bind="text: getValue()">{{payOutDetail &&
                                                            getPrice(payOutDetail.totalAmountWithVat
                                                            | currency:"ZAR":"R")}}</span></strong>
                                                </td>
                                            </tr>

                                        </tbody>
                                    </table>
                                </div>

                            </div>
                            <div class="cart-summary">
                                <!-- <div *ngIf="discountPercent>0" id="uf-yousave-ko" class="uf_yousaved_box special-price"
                                    data-bind="scope:'utsav-yousave-ko'">
    
                                    <span class="uf_yousave_text" data-bind="afterRender: getYouSaved()">You saved <b><span
                                                class="price">{{discountPercent}}%</span></b> on this purchase.</span>
    
                                </div> -->
                                <ul class="checkout methods items checkout-methods-items">
                                    <li class="item" *ngIf="!htmlData">
                                        <!-- <button type="button" *ngIf="flag" data-role="proceed-to-checkout"
                                        (click)="buyOrder(1)"title="Proceed to Checkout"
                                            class="action primary checkout"><span>Proceed to
                                                Checkout</span></button> -->
                                        <dropdown-button *ngIf="flag" [dropDownData]="dropDownData" (selectPayment)="selectPayment($event)">

                                        </dropdown-button>
                                        <button type="button" *ngIf="!flag" data-role="proceed-to-checkout"
                                            title="Proceed to Checkout"
                                            class="action primary checkout op05"><span>Loading..</span></button>

                                    </li>
                                    <li class="item" *ngIf="htmlData">
                                        <!-- <button type="button" *ngIf="flag" data-role="proceed-to-checkout"
                                        (click)="buyOrder(1)"title="Proceed to Checkout"
                                            class="action primary checkout"><span>Proceed to
                                                Checkout</span></button> -->
                                       <div [innerHTML]="htmlData"></div>
                                        <!-- <button type="button"  data-role="proceed-to-checkout"
                                            title="Proceed to Checkout"
                                            class="action primary checkout op05"><span>Loading..</span></button> -->

                                    </li>
                                </ul>
                            </div>
                        </div>

                    </div>
                </div>
            </div>
        </div>
    </section>


</div>


<div class="modal fade" id="itemDeleteModal" tabindex="-1" role="dialog" aria-labelledby="exampleModalLabel"
    aria-hidden="true">
    <div class="modal-dialog modal-dialog-centered" role="document">
        <div class="modal-content ggg" style="width: 77%;">
            <div class="d-flex justify-content-center mt-5 mb-4" id="book">
                <img class="for-img1" style="height: 100%;width: 18%;" src="assets/latest/logoutui.png" alt="login form"
                    class="img-fluid" />
            </div>
            <h2 class="modal-title d-flex justify-content-center okay" style="color:#263238;">
                Are you sure you want to delete <br> this item?
            </h2>
            <div class="modal-body d-flex justify-content-center mb-4">
                <button type="button" class="btn btntt1" data-dismiss="modal">
                    No
                </button>
                <button type="button" class="btn btntt ml-3" data-dismiss="modal" (click)="removeCartList()">
                    Yes
                </button>
            </div>
        </div>
    </div>
</div>
<div class="modal fade" id="choosePaymentModal" tabindex="-1" role="dialog" aria-labelledby="exampleModalLabel"
    aria-hidden="true">
    <div class="modal-dialog modal-dialog-centered" role="document">
        <div class="modal-content ggg" style="width: 77%;">
            <div class="d-flex justify-content-center mt-5 mb-4" id="book">
                <img class="for-img1" style="height: 100%;width: 18%;" src="assets/latest/logoutui.png" alt="login form"
                    class="img-fluid" />
            </div>
            <h2 class="modal-title d-flex justify-content-center okay" style="color:#263238;">
                Sele
            </h2>
            <div class="modal-body d-flex justify-content-center mb-4">
                <button type="button" class="btn btntt1" data-dismiss="modal">
                    No
                </button>
                <!-- <button type="button" class="btn btntt ml-3" data-dismiss="modal" (click)="buyOrder(false)">
                    Pay Fast
                </button>
                <button type="button" class="btn btntt ml-3" data-dismiss="modal" (click)="buyOrder(true)">
                    Ozow Payment
                </button> -->
            </div>
        </div>
    </div>
</div>