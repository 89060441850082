<div class="container wrapper-content">
    <p class="faq">Frequently Asked Questions</p>
    <div id="accordion">
        <mat-accordion>
            <mat-expansion-panel (opened)="panelOpenState = true" (closed)="panelOpenState = false" *ngFor="let item of result;let i = index" [expanded]="i == 0">
                <mat-expansion-panel-header class="faq-qsn">
                    <mat-panel-title class="gg" style="color:rgb(73, 116, 113) ;" innerHtml="{{item?.question || '---'}}">
                        <!-- {{item?.question || '---'}} -->
                    </mat-panel-title>
                </mat-expansion-panel-header>
                <p innerHtml="{{item?.answer || '---'}}"></p>
            </mat-expansion-panel>
        </mat-accordion>
    </div>
</div>