<!-- <app-header></app-header>
<router-outlet></router-outlet>
<app-footer></app-footer> -->
<!-- <ngx-spinner type="ball-scale-multiple"></ngx-spinner> -->
<ng-container *ngIf="view">
    <app-header></app-header>
    <router-outlet></router-outlet>
    <app-footer></app-footer>
</ng-container>
<ng-container *ngIf="!view">
    <router-outlet></router-outlet>
</ng-container>


<!-- Button trigger modal -->
<!-- <button type="button" class="btn btn-primary" data-toggle="modal" data-target="#exampleModal">
    Launch demo modal
</button> -->

<!-- Modal -->
<div class="modal fade" id="exampleModal" tabindex="-1" role="dialog" aria-labelledby="exampleModalLabel"
    aria-hidden="true">
    <div class="modal-dialog" role="document">
        <div class="modal-content" style="margin-top: 230px;">
            <div class="modal-header" style="height: 90px; background: rgb(73, 116, 113);">
                <h5 class="modal-title" id="exampleModalLabel" style="height:60px"><img
                        style="height: 100%; width:100%; object-fit: cover;" src="assets/latest/exobe-logo.png" alt=""></h5>
                <button type="button" class="close" data-dismiss="modal" aria-label="Close">
                    <!-- <span aria-hidden="true" style="color: white;">&times;</span> -->
                </button>
            </div>
            <div class="modal-body" style="background: rgb(73, 116, 113);">
                <div class="row justify-content-center">
                    <div class="col-md-6 text-center">
                        <div class="popup" (click)="navigateTo('CUSTOMER')">
                            <img src="assets/latest/customer2.png" alt="">
                        </div>
                    </div>
                    <div class="col-md-6 text-center">
                        <div class="popup" (click)="navigateTo('RETAILER')">
                            <img src="assets/latest/retailer2.png" alt="">
                        </div>
                    </div>
                    <div class="col-md-11 text-center mt-3" style="background: #fff;">
                        <div class="popup" (click)="navigateTo('SERVICE_PROVIDER')">
                            <img src="assets/latest/service.png" alt="">
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>