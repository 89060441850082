<div class="container">
  <app-linker-route [headingName]="headingName" [linkerRouteItems]="linkerRouteItems"></app-linker-route>
</div>


<div class="container mt-2 mb-5">

  <div class="">


    <div class="row mt-3 mb-5">
      <div class="col-lg-5 col-md-5 col-sm-5">
        <div class="row">
          <span>
            <img class="image-custom qwqwqw" [hidden]="!isLoaded" (load)="onLoadImage()"
              [src]="UserDetailsData?.profilePic" />
            <ngx-skeleton-loader *ngIf="!isLoaded" count="5" [theme]="{ 'border-radius': '0', height: '50px' }">
            </ngx-skeleton-loader>

          </span>
        </div>

      </div>
      <div class="col-lg-7 col-md-7 col-sm-7">
        <div class="row">
          <div class="col-9 pl-0">
            <p class="prdct-home">
              {{ UserDetailsData?.ownerFirstName }}
              {{ UserDetailsData?.ownerLastName }}
            </p>
            <p>Details of Selected Service</p>
          </div>
          <div class="col-3 pl-0">
            <button class="btn btn-theme int" (click)="openModal()">Preferred Price</button>
          </div>
        </div>
        <div class="" style="margin-top: 1em">
          <h3>{{ categoryName }}</h3>
          <div id="accordion" *ngFor="
              let item of commonService.removeDuplicate(selectedService, [
                '_id'
              ]);
              let i = index
            ">
            <mat-accordion>
              <mat-expansion-panel [expanded]="step === 0" (opened)="setStep(0)" hideToggle>
                <!-- <mat-expansion-panel (opened)="panelOpenState = true" (closed)="panelOpenState = false" > -->
                <mat-expansion-panel-header class="faq-qsn">
                  <!-- <mat-panel-title class="gg" style="color:rgb(73, 116, 113) ;"> -->
                  <mat-panel-title class="gg" style="color: rgb(73, 116, 113)">
                    {{ item?.subCategory?.subCategoryName || "---" }}
                  </mat-panel-title>
                </mat-expansion-panel-header>
                <!-- <p innerHtml="{{item?.answer || '---'}}"></p> -->
                <div class="d-flex justify-content-between" *ngFor="let data of item?.serviceArray"
                  style="text-align: center; align-items: center">
                  <div class="" style="padding: 0px !important;">
                    <!-- <input type="checkbox" class="custom-control-input" [attr.id]="'customControlInline'+i"> -->
                    <p class="">{{ data?.serviceName }}</p>
                  </div>
                  <div class="d-flex mb-0" style="min-width: 115px">
                    <button class="btn qty-less-high px-3 me-2" [disabled]="data.quantity <= 1" (click)="
                        updateCartValue('SUB');
                        data.quantity = data.quantity - 1
                      " onclick="this.parentNode.querySelector('input[type=number]').stepDown()">
                      <i class="fa fa-minus" style="margin-left: -5px"></i>
                    </button>

                    <div class="form-outline">
                      <input id="form1" min="0" name="quantity" [(ngModel)]="data.quantity" type="number"
                        class="form-control new-inp" disabled />
                      <!-- <label class="form-label" for="form1">Quantity</label> -->
                    </div>

                    <button class="btn qty-less-high px-3 ms-2" (click)="
                        updateCartValue('ADD');
                        data.quantity = data.quantity + 1
                      " onclick="this.parentNode.querySelector('input[type=number]').stepUp()">
                      <i class="fa fa-plus" style="margin-left: -5px"></i>
                    </button>
                  </div>
                  <div *ngIf="!data?.isCampaignActive">
                    <p class="mb-0 ner" *ngIf="!data.dealDiscount">
                      {{
                      data?.price &&
                      getPrice(
                      data?.price * data.quantity | currency : "ZAR" : "R"
                      )
                      }}/unit
                    </p>

                    <p class="mb-0 ner" *ngIf="data.dealDiscount">

                      {{
                      data?.dealPrice &&
                      getPrice(
                      data?.dealPrice * data.quantity | currency : "ZAR" : "R"
                      )
                      }}/unit


                    </p>

                  </div>
                  <div *ngIf="data?.isCampaignActive">
                    <p class="mb-0 ner">
                      {{
                      data?.price &&
                      getPrice(
                      data?.campainId * data.quantity | currency : "ZAR" : "R"
                      )
                      }}/unit
                    </p>

                    <p class="mb-0 ner">

                      {{
                      data?.campainId?.serviceDiscountedPrice &&
                      getPrice(
                      data?.campainId?.serviceDiscountedPrice * data.quantity | currency : "ZAR" : "R"
                      )
                      }}/unit

                      ({{getDiscount(data?.price, data?.campainId?.serviceDiscountedPrice)}}% Off )
                    </p>

                  </div>
                </div>
              </mat-expansion-panel>
            </mat-accordion>
          </div>
        </div>

        <div class="mt-4">
          <div class="row justify-content-between mt-4 text-center" style="align-items: center">
            <h1 class="amount-details-text">Amount Details</h1>
          </div>
          <div class="row justify-content-between text-center" style="align-items: center">
            <div class="totalamout">
              <span class="amnt-total-details">Sub-total</span>
            </div>
            <div class="totalamout">
              <span class="total-price-sum">{{
                priceValueOfService &&
                getPrice(priceValueOfService | currency : "ZAR" : "R")
                }}</span>
            </div>
          </div>
          <div class="row justify-content-between mt-1 text-center" style="align-items: center">
            <div class="totalamout">
              <span class="amnt-total-details">VAT @15%</span>
            </div>
            <div class="totalamout">
              <span class="total-price-sum">{{
                sumToatal && getPrice(sumToatal | currency : "ZAR" : "R")
                }}</span>
            </div>
          </div>
          <div class="row justify-content-between mt-1 text-center" style="align-items: center">
            <div class="totalamout">
              <span class="amnt-total-details">Total to pay</span>
            </div>
            <div class="totalamout">
              <span class="total-price-sum">{{
                totalPayAmount &&
                getPrice(totalPayAmount | currency : "ZAR" : "R")
                }}</span>
            </div>
          </div>
        </div>

        <div class="mt-4">

          <div class="row">

            <p>Share your details for the delivery of the service</p>
            <div class="container card card-data" *ngIf="addressId">
              <div class="row">
                <div class="col-lg-8 col-md-8 mb-4 mb-lg-0">
                  <p style="margin: 1px; margin-top: 5px">
                    <strong class="text-sty">{{ profileData?.addressLine1 }}
                    </strong>
                  </p>
                  <p style="margin: 1px; margin-top: 5px">
                    <strong class="text-sty">{{ profileData?.city }}, {{ profileData?.state }},{{
                      profileData?.country
                      }}, {{ profileData?.zipCode }}</strong>
                  </p>
                  <div class="mt-3">
                    <span><i class="fa fa-phone" aria-hidden="true" style="color: #c01e2f"></i>
                    </span>
                    &nbsp;&nbsp;
                    <span class="phoen">{{ profileData?.countryCode }}
                      {{ profileData?.mobileNumber }}</span>
                  </div>
                </div>

                <div class="col-lg-4 col-md-4 mb-4 mb-lg-0">
                  <div class="d-flex trash" style="max-width: 300px">
                    <i class="fa fa-edit ml-2" style="font-size: 22px; cursor: pointer"
                      (click)="navigateToEdit('quantity')"></i>
                  </div>
                </div>
              </div>
            </div>
            <div class="row justify-content-between" style="padding: 10px 0">
              <div class="col-md-12 m-0 p-0">
                <button class="add-cart-btn" (click)="navigateToserviceQuantity('quantity')">
                  +Add new address
                </button>
              </div>
              <!-- <div>
                                <button class="continue-btn" (click)="navigateToOrderOverview(a)">Continue with this address</button>
                            </div> -->
            </div>
          </div>
          <div class="row" style="padding: 10px 0px">
            <div class="col-md-6 pl-0 mb-3">
              <div id="date-picker-example" class="md-form md-outline input-with-post-icon datepicker " inline="true">
                <label for="">Choose Date<span class="dataColor">*</span></label>
                <input placeholder="Select date" type="date" id="example" [min]="todayDate"
                  (input)="getQuantity($event.target.value)" [(ngModel)]="duration"
                  class="form-control change-clr heit" />
              </div>
            </div>
            <div class="mt-1 col-md-6 pl-0 mb-3">
              <label for="">Choose Time Slot<span class="dataColor">*</span></label>
              <select class="form-control change-clr" [(ngModel)]="timeSlots">
                <option value="" selected disabled>Select Slot</option>
                <option *ngFor="let item of slotData" [value]="item">
                  {{ item }}
                </option>
              </select>
            </div>
          </div>
        </div>
        <div class="mt-2">
          <div class="">
            <div class="butCh mb-5" style="margin-bottom: 80px!important;">
              <!-- <div *ngIf="discountPercent>0" id="uf-yousave-ko" class="uf_yousaved_box special-price"
                  data-bind="scope:'utsav-yousave-ko'">

                  <span class="uf_yousave_text" data-bind="afterRender: getYouSaved()">You saved <b><span
                              class="price">{{discountPercent}}%</span></b> on this purchase.</span>

              </div> -->
              <ul class="checkout methods items checkout-methods-items">
                <li class="item" *ngIf="!htmlData">
                  <!-- <button type="button" *ngIf="flag" data-role="proceed-to-checkout"
                      (click)="buyOrder(1)"title="Proceed to Checkout"
                          class="action primary checkout"><span>Proceed to
                              Checkout</span></button> -->
                  <dropdown-button *ngIf="flag" [dropDownData]="dropDownData" (selectPayment)="selectPayment($event)">

                  </dropdown-button>
                  <button type="button" *ngIf="!flag" data-role="proceed-to-checkout" title="Proceed to book"
                    class="action primary checkout op05"><span>Loading..</span></button>

                </li>
                <li class="item" *ngIf="htmlData">
                  <!-- <button type="button" *ngIf="flag" data-role="proceed-to-checkout"
                      (click)="buyOrder(1)"title="Proceed to Checkout"
                          class="action primary checkout"><span>Proceed to
                              Checkout</span></button> -->
                  <div [innerHTML]="htmlData"></div>
                  <!-- <button type="button"  data-role="proceed-to-checkout"
                          title="Proceed to Checkout"
                          class="action primary checkout op05"><span>Loading..</span></button> -->

                </li>
              </ul>
            </div>
            <!-- routerLink="/customer-service-quantity-add" -->
            <!-- <span>
              <button class="butCh" *ngIf="!onLoadButton" (click)="bookService()">
                Proceed to Book
              </button>
              <button class="butCh" *ngIf="onLoadButton" style="opacity: 0.75;">
                Loading...
              </button>

            </span> -->
          </div>
        </div>
      </div>
    </div>
  </div>
</div>

<div class="modal fade" id="listAddressModal" tabindex="-1" role="dialog" aria-labelledby="exampleModalLabel"
  aria-hidden="true">
  <div class="modal-dialog" role="document">
    <div class="modal-content ggg" style="min-width: 600px">
      <div class="modal-header">
        <button type="button" class="close" data-dismiss="modal" aria-label="Close">
          <span aria-hidden="true">&times;</span>
        </button>
      </div>

      <div class="right-side">
        <h1 class="container right-side-text">Address</h1>
        <div class="container mt-4 card card-data" *ngFor="let item of listAddressData">
          <div class="row" (click)="toAddressId(item?._id)">
            <div class="col-lg-8 col-md-8 mb-4 mb-lg-0">
              <p style="margin: 1px; margin-top: 5px">
                <strong class="text-sty">
                  {{item?.addressLine1}} <span *ngIf="item?.addressLine2">,</span>
                  {{item?.addressLine2}}
                </strong>
              </p>
              <p style="margin: 1px; margin-top: 5px">
                <strong class="text-sty">{{ item?.city }}, {{ item?.state }},{{ item?.country }},
                  {{ item?.zipCode }}</strong>
              </p>
              <div class="mt-3">
                <span><i class="fa fa-phone" aria-hidden="true" style="color: #c01e2f"></i>
                </span>
                &nbsp;&nbsp;
                <span class="phoen">{{ item?.countryCode }} {{ item?.mobileNumber }}</span>
              </div>
            </div>

            <div class="col-lg-4 col-md-4 mb-4 mb-lg-0">
              <div class="d-flex trash" style="max-width: 300px">
                <i class="fa fa-edit ml-2" style="font-size: 22px" (click)="navigateToEdit('quantity')"></i>
                <i class="fa fa-trash ml-2" (click)="addressModals(item?._id, item?.isPrimary)"
                  style="font-size: 21px; color: rgb(73, 116, 113)"></i>
              </div>

            </div>
          </div>
        </div>

      </div>


    </div>
  </div>
</div>
<!-- delete address Modal -->
<div class="modal fade" id="addressModal" tabindex="-1" role="dialog" aria-labelledby="exampleModalLabel"
  aria-hidden="true">
  <div class="modal-dialog modal-dialog-centered" role="document">
    <div class="modal-content ggg">
      <div class="modal-header">
        <button type="button" class="close" data-dismiss="modal" aria-label="Close">
          <span aria-hidden="true">&times;</span>
        </button>
      </div>
      <div class="d-flex justify-content-center mt-5 mb-4" id="book">
        <img class="for-img1" style="height: 100%" src="assets/images/book.png" alt="login form" class="img-fluid" />
      </div>
      <h2 class="modal-title d-flex justify-content-center">
        Are you sure you want to delete this address?
      </h2>

      <div class="modal-body d-flex justify-content-center mb-4">
        <button type="button" class="btn btntt1" data-dismiss="modal" routerLink="/homepage">
          No
        </button>
        <button type="button" class="btn btntt ml-3" data-dismiss="modal" (click)="deleteAddress()">
          Yes
        </button>
      </div>
    </div>
  </div>
</div>



<div class="modal fade" id="intrestedPriceModal" tabindex="-1" role="dialog" aria-labelledby="exampleModalLabel"
  aria-hidden="true">
  <div class="modal-dialog" role="document">
    <div class="modal-content">
      <div class="modal-header" style="height: 90px; background: rgb(73, 116, 113);">
        <h5 class="modal-title" id="exampleModalLabel" style="width: 135px; height:60px"><img
            style="height: 100%; width:100%; object-fit: cover;" src="assets/latest/exobe-logo.png" alt=""></h5>
        <button type="button" class="close" data-dismiss="modal" aria-label="Close">
          <!-- <span aria-hidden="true" style="color: white;">&times;</span> -->
        </button>
      </div>

      <div class="modal-body">
        <div class="form">




          <div class="row">

            <div class="col-md-12 mb-4">
              <label for="exampleInputEmail1">Select Service Category</label>
              <select class="form-control sel-2" [(ngModel)]="_categoryId"
                (change)="getSubCatList($event.target.value)">
                <option value="">Select category</option>

                <option *ngFor="let item of serviceList" [value]="item?.subCategory?.categoryId?._id">
                  {{ item?.subCategory?.subCategoryName}}

                </option>
              </select>
            </div>

          </div>
          <div class="row">
            <div class="col-md-12 mb-4">
              <label for="exampleInputEmail1">Select Service</label>
              <select class="form-control sel-2" [(ngModel)]="_serviceId"
                (change)="getPriceService($event.target.value)">
                <option value="">Select Services</option>
                <ng-container *ngFor="let item of subServiceList">
                  <option *ngIf="!item.isIntrestPriceAdded" [value]="item?._id">
                    {{ item?.serviceName}}

                  </option>
                </ng-container>

              </select>
            </div>
          </div>
          <div class="row">
            <div class="col-md-12 form-group">
              <label for="exampleInputEmail1">Actual Price</label>
              <input type="text" class="form-control" [(ngModel)]="servicePrice" readonly aria-describedby="toDate"
                placeholder="Enter Actual Price" (keypress)="commonService.numberOnly($event)"
                (keypress)="commonService.preventSpace($event);commonService.numberOnly($event)">
            </div>
          </div>
          <div class="row">
            <div class="col-md-12 form-group">
              <label for="exampleInputEmail1">Interested Price</label>
              <input type="text" class="form-control" [(ngModel)]="_discountedPrice" aria-describedby="toDate"
                placeholder="Enter Interested Price" (keypress)="commonService.numberOnly($event)"
                (keypress)="commonService.preventSpace($event);commonService.numberOnly($event)">
            </div>

          </div>
          <div *ngIf="servicePrice < _discountedPrice" style="color: rgb(73, 116, 113);text-align: center;">
            Invalid Price
          </div>

          <div class="form-group d-flex">
            <div>
              <input type="radio" (change)="spordicType = 'AUTOMATIC'" name="spordaicSelection" id="auto-spordaic"
                [checked]="spordicType === 'AUTOMATIC'">
              <label for="auto-spordaic" style="margin-top: 10px;">Automatic : Schedule Automatic when campaign is
                started</label>
            </div>


          </div>
          <div class="form-group d-flex">

            <div>
              <input type="radio" (change)="spordicType = 'SEMIAUTOMATIC'" name="spordaicSelection" id="semi-spordaic"
                [checked]="spordicType === 'SEMIAUTOMATIC'">
              <label for="semi-spordaic" style="margin-top: 10px;">Semi Automatic : Let me review the scheduled
                date</label>
            </div>

          </div>


          <div class="col-md-12 text-center mt -3">
            <button class="btn btn-theme" (click)="startCampaign()"
              [disabled]="servicePrice < _discountedPrice">Submit</button>
          </div>
        </div>
      </div>




    </div>
  </div>
</div>