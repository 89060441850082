<div class="container mb-4 wrapper-content"></div>


<div class="modal fade" id="roleModal" tabindex="-1" role="dialog" aria-labelledby="exampleModalLabel" aria-hidden="true">
    <div class="modal-dialog modal-dialog-centered" role="document">
        <div class="modal-content">
            <div class="modal-header" style="height: 90px; background: rgb(73, 116, 113);">
                <h5 class="modal-title" id="exampleModalLabel" style="width: 135px; height:60px"><img style="height: 100%; width:100%; object-fit: cover;" src="assets/latest/exobe-logo.png" alt=""></h5>

            </div>
            <div class="modal-body" style="background: rgb(73, 116, 113);">
              <div *ngIf="title" class="row justify-content-center">
                <h3 class="text-white" style="    font-size: 20px;
                font-weight: 500;">{{title}}</h3>
              </div>
                <div class="row justify-content-center">
                    <div class=" text-center division" [ngClass]="i == 0 && serviceList?.length > 2 ? 'col-12' : 'col-6'" *ngFor="let item of serviceList;let i = index">
                      <div class="role-container" (click)="onNavigate(item)" >
                     
                      
                        <div class="img-container">
                          <img *ngIf="item?.category?.categoryType == 'SERVICE_PROVIDER'" [src]="'https://exobe.s3.af-south-1.amazonaws.com/1694516619481_convert2.jpeg'" alt="">
                          <img *ngIf="item?.category?.categoryType != 'SERVICE_PROVIDER'" [src]="item?.category?.categoryImage || 'assets/social/pickupdriver.png'" alt="">
                        </div>
                        <p>{{ item?.name}}</p>
                      </div>
                    </div>
                   
                   
                  </div>
            </div>
        </div>
    </div>
</div>