<div class="container  fw-b wrapper-content">
    <app-linker-route [headingName]="headingName" [linkerRouteItems]="linkerRouteItems"></app-linker-route>
    <div class="container mb-4 wrapper-content">
        <div class="row justify-content-center position-relative">
            <div class="col-3 text-center">
                <div class="cardWallet walletBlock bg-blue">

                    <p class="text-white amt">{{ wallet?.walletAmount && getPrice( wallet?.walletAmount |
                        currency:"ZAR":"R") || 'R 0.00'}}</p>
                </div>
                <div>

                    <p class="text-theme">Wallet Balance</p>
                </div>

            </div>
            <div class="position-absolute" style="top:-30px;right:0px;">
                <button class="btn btn-theme" (click)="openModal()">Add Money to Wallet</button>
            </div>
            <!-- <div class="col-3 text-center ml-1">
                <div class="cardWallet walletBlock bg-red">

                    <p class="text-white amt">{{ wallet?.totalWalletAmount && getPrice( wallet?.totalWalletAmount |
                        currency:"ZAR":"R") || 'R 0.00'}}</p>
                </div>
                <div>

                    <p class="text-theme">Total Earning</p>
                </div>

            </div>
            <div class="col-3 text-center ml-1">
                <div class="cardWallet walletBlock bg-theme">

                    <p class="text-white amt">{{ wallet?.totalCommissionPaid && getPrice( wallet?.totalCommissionPaid |
                        currency:"ZAR":"R") || 'R 0.00'}}</p>
                </div>
                <div>

                    <p class="text-theme">Total Commission Paid</p>
                </div>

            </div> -->
        </div>

        <div class="row mt-3 ">
            <div class="col-12 table-responsive">
                <table class="table table-hover">
                    <thead>
                        <tr>
                            <th scope="col">S.No</th>
                            <th scope="col">From Wallet</th>
                            <th scope="col">To Wallet</th>
                            <th scope="col">Amount in R</th>

                            <th scope="col">Txn Id </th>
                            <th scope="col">Txn. Status</th>
                            <th scope="col">Txn. Date</th>

                        </tr>
                    </thead>
                    <tbody>
                        <tr *ngFor="
                let item of orderList
                  | paginate
                    : {
                        itemsPerPage: pageSize,
                        currentPage: currentPage,
                        totalItems: count
                      };
                let i = index
              ">
                            <th>{{ 10 * (currentPage - 1) + i + 1 }}</th>
                            <th scope="row">
                                <ng-container *ngIf="item?.fromWalletType == 'OZW'">
                                    OZOW Payment
                                    ({{item?.fromWalletType}})

                                </ng-container>
                                <ng-container *ngIf="item?.fromWalletType == 'PFW'">
                                    PAY FAST Payment
                                    ({{item?.fromWalletType}})

                                </ng-container>
                                <ng-container *ngIf="item?.fromWalletType != 'OZW' && item?.fromWalletType != 'PFW' ">
                                    {{item?.fromUser?.firstName}} {{item?.fromUser?.lastName}}
                                    ({{item?.fromWalletType}})

                                </ng-container>

                            </th>
                            <th scope="row">
                                <ng-container *ngIf="item?.toWalletType == 'OZW'">
                                    OZOW Payment
                                    ({{item?.toWalletType}})

                                </ng-container>
                                <ng-container *ngIf="item?.toWalletType == 'PFW'">
                                    PAY FAST Payment
                                    ({{item?.toWalletType}})

                                </ng-container>
                                <ng-container *ngIf="item?.toWalletType != 'OZW' && item?.toWalletType != 'PFW'">
                                    {{ item?.toUser?.firstName || '--' }} {{ item?.toUser?.lastName }}
                                    ({{item?.toWalletType}})
                                </ng-container>

                            </th>

                            <td [ngClass]="item?.toUser?._id == myId ? 'text-success' : 'text-theme'">
                                {{item?.toUser?._id == myId ? '+' : '-' }} {{
                                item?.transactionAmount && getPrice(item?.transactionAmount | currency:"ZAR":"R")
                                ||'--'}}
                            </td>

                            <td>{{ item?._id }}</td>
                            <td>{{ item?.transactionStatus }}</td>

                            <td>{{ item?.createdAt | date : "medium" || "--" }}</td>


                        </tr>

                    </tbody>
                </table>
                <app-table-loader [isTable]="'TABLE'" [isLoading]="isLoading" [notFoundData]="notFoundData"
                    [colspan]="10" [isWidth]="'55px'"></app-table-loader>

                <pagination-controls *ngIf="count > 10" style="float: right; margin-top: 3%"
                    (pageChange)="changePageNumber($event)"></pagination-controls>
            </div>

        </div>
    </div>
</div>

<div class="modal fade" id="addMoneyModal" tabindex="-1" role="dialog" aria-labelledby="exampleModalLabel"
    aria-hidden="true">
    <div class="modal-dialog" role="document">
        <div class="modal-content" style="margin-top: 230px;">
            <div class="modal-header" style="height: 90px; background: rgb(73, 116, 113);">
                <h5 class="modal-title" id="exampleModalLabel" style="width: 135px; height:60px"><img
                        style="height: 100%; width:100%; object-fit: cover;" src="assets/latest/exobe-logo.png" alt=""></h5>
                <button type="button" class="close" data-dismiss="modal" aria-label="Close">
                    <!-- <span aria-hidden="true" style="color: white;">&times;</span> -->
                </button>
            </div>
            <div class="modal-body">
                <div class="row justify-content-center">

                    <div class="col-12 mb-2">
                        <input type="text" placeholder="Enter Amount" [(ngModel)]="amount" placeholder="0"
                            (input)="commonService.numberOnly($event)" class="form-control">
                    </div>
                    <b class="col-12 text-center">Select Payment Method</b>
                    <div class="payment-method col-4 mt-3">
                        <input type="radio" (change)="getPayamnetMethod('pay-fast')" id="credit-card" name="payment"
                            value="pay-fast">
                        <label for="credit-card">Pay Fast</label>
                    </div>

                    <div class="payment-method col-4 mt-3">
                        <input type="radio" id="paypal" (change)="getPayamnetMethod('ozow')" name="payment"
                            value="ozow">
                        <label for="paypal">Ozow</label>
                    </div>
                    <div class="col-12 text-center mt-3">
                        <button class="btn btn-theme" *ngIf="!htmlData" [disabled]="!paymentMethod"
                            (click)="onProceed()">Proceed to Add money</button>
                        <div *ngIf="htmlData" [innerHtml]="htmlData"></div>
                        <!-- <button class="btn btn-theme" *ngIf="htmlData" [disabled]="!paymentMethod" (click)="onProceed()">Proceed to Add money</button> -->
                    </div>
                </div>

            </div>
        </div>
    </div>
</div>