<div class="container mb-5 wrapper-content">
    <app-linker-route [headingName]="headingName" [linkerRouteItems]="linkerRouteItems"></app-linker-route>
    <div>
        <span class="dateset">Ordered on {{orderDataDetials?.createdAt | date : 'mediumDate' }} </span>
        <span class="line"> </span>
        <span class="orderSet">Order {{orderDataDetials?.orderId}}</span>
    </div>
    <div class="right-side" style="width: 100%;">
        <div class="row mt-3">
            <div class="col-md-5">
                <div class="row">
                    <div class="container">
                        <p class="titlem">Product</p>
                        <div class=" mt-4 " *ngFor="let item of viewOrderData">
                            <div class="row">
                                <div class="col-lg-3 col-md-12 mb-4 mb-lg-0">
                                    <div class="bg-image hover-overlay hover-zoom ripple rounded"
                                        data-ripple-color="light">
                                        <img [src]="item?.productId?.thumbnail" class="productImageNew" alt="" />
                                        <a>
                                            <div class="mask" style="background-color: rgba(251, 251, 251, 0.2)"></div>
                                        </a>
                                    </div>
                                </div>
                                <div class="col-lg-9 col-md-12 mb-4 mb-lg-0">
                                    <p style="margin: 1px;" class="product">
                                        {{item?.productId?.productName}}
                                    </p>
                                    <p class="new-pr" style="margin: 1px;">
                                        {{getPrice((item?.price) |
                                        currency:"ZAR":"R")}}
                                    </p>
                                    <p class="mb-0 deliv">Qty: {{item?.quantity}}</p>

                                    <p class="mb-1 deliv">Value/Size:
                                        {{item?.priceSizeDetails?.value}} <span
                                            *ngIf="item?.priceSizeDetails?.unit">-</span>
                                        {{item?.priceSizeDetails?.unit}}</p>
                                    <p class="mb-0 deliv">Product Id: {{item?._id}}</p>

                                </div>

                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div class="col-md-4">
                <div class="row">
                    <div class="container">
                        <p class="titlem">Deliver To</p>
                        <div class="row">
                            <div class="col-lg-12 col-md-12 mb-4 mb-lg-0">
                                <p style="margin: 1px;margin-top: 5px;" class="text-sty text-del">
                                    {{orderDataDetials?.shippingAddress?.firstName}}
                                    {{orderDataDetials?.shippingAddress?.lastName}}

                                </p>
                                <p style="margin: 1px;margin-top: 3px;" class="text-sty text-del">
                                    {{orderDataDetials?.shippingAddress?.email}}


                                </p>
                                <p style="margin: 1px;margin-top: 3px;" class="text-sty text-del">
                                    {{orderDataDetials?.shippingAddress?.address}}
                                    {{orderDataDetials?.shippingAddress?.addressLine1}}
                                    {{orderDataDetials?.shippingAddress?.addressLine2}},
                                </p>

                                <p style="margin: 1px;margin-top: 3px;" class="text-sty text-del">
                                    {{orderDataDetials?.shippingAddress?.city}},
                                    {{orderDataDetials?.shippingAddress?.state}},{{orderDataDetials?.shippingAddress?.country}},
                                    {{orderDataDetials?.shippingAddress?.zipCode}}
                                </p>
                                <div class="mt-1">
                                    <span class="phoen">{{orderDataDetials?.shippingAddress?.countryCode}}
                                        {{orderDataDetials?.shippingAddress?.mobileNumber}}</span>
                                </div>
                                <p style="margin: 1px;margin-top: 5px;"><strong class="text-sty"> <a
                                            style="color: rgb(73, 116, 113); cursor: pointer; text-decoration: none;"
                                            [href]="'https://www.google.com/maps/@'+orderDataDetials?.shippingAddress?.location?.coordinates[1]+','+orderDataDetials?.shippingAddress?.location?.coordinates[0]+',10z'"
                                            target="_blank" rel="noopener noreferrer">
                                            Find Location
                                        </a></strong>
                                </p>
                            </div>

                        </div>
                    </div>
                </div>
            </div>
            <div class="col-md-3">
                <div class="row">
                    <div class="container">
                        <p class="titlemPay">Payment Details</p>
                        <div class="">
                            <div class=" justify-content-between  text-center"
                                style="align-items: center;margin-left: 8px;">

                                <ul>
                                    <li style="white-space: pre;">
                                        <span>Total Amount : </span>
                                        <span>

                                            {{orderDataDetials?.myOrderPrice && getPrice(orderDataDetials?.myOrderPrice
                                            | currency:"ZAR":"R")}}
                                        </span>
                                    </li>
                                    <li style="margin-top: 5px;white-space: pre;">
                                        <span>Payment Mode : </span>
                                        <span style="font-size:14px;">
                                            {{transactionData?.transactionMethod}}
                                        </span>
                                    </li>
                                    <li style="margin-top: 5px;white-space: pre;">
                                        <span> ID : </span>
                                        <span style="font-size:14px;">
                                            {{transactionData?.trxId}}
                                        </span>
                                    </li>
                                </ul>
                            </div>

                        </div>
                    </div>
                </div>
            </div>

        </div>
    </div>
    <order-tracker [orderDataDetials]="orderDataDetialsTracking"></order-tracker>
    <star-rating *ngIf="feedBackData" [feedBackData]="feedBackData" [isFeedbackDataSubmited]="isFeedbackDataSubmited"
        (submitFeedBack)="submitFeedBack($event)"></star-rating>


    <!-- <div class="ui-footer row justify-content-between mt-2 text-center" style="align-items: center;">
        <span class="m-auto">
            <button class="butCh" *ngIf="orderDataDetials?.deliveryStatus == 'PENDING'"
            (click)="markAsDone()">Mark As Done</button>
        </span>

    </div> -->


</div>
<otp-modal *ngIf="openModal" [orderId]="_id" (closeModal)="openModal = false"></otp-modal>