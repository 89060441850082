<auth-layout title="Customer Login" subTitile="Access the Bazarhub using your email and password">
  <form [formGroup]="loginForm">
    <div class="form-group">
      <label for="username">Email Address<span class="redis">*</span></label>
      <div class="input-control">
        <input type="text" class="form-control" placeholder="Enter your email" type="text" formControlName="email"
          maxlength="256" (keypress)="preventSpace($event)" id="username">


        <span class="eyeicon">
          <img src="assets/latest/email.svg" alt="">
        </span>
      </div>
      <div class="for-validation mb-4">
        <div class="for-validation text-danger"
          *ngIf="loginForm.get('email')?.hasError('required') && loginForm.get('email')?.touched">
          *Please enter email id.
        </div>
        <div class="for-validation text-danger"
          *ngIf="loginForm.get('email')?.hasError('pattern') && loginForm.get('email')?.dirty">
          *Please enter a valid email.

        </div>
      </div>
    </div>
    <div class="form-group">
      <label for="username">Password<span class="redis">*</span></label>
      <div class="input-control">
        <input placeholder="Enter your password" [type]="changetype?'password':'text'" class="form-control"
          id="password" maxlength="16" (keypress)="preventSpace($event)" onselectstart="return false;"
          onpaste="return false;" oncopy="return false;" oncut="return false" ondrag="return false"
          ondrop="return false" autocomplete="off" formControlName="password">
        <span class="eyeicon" (click)="changetype = !changetype">
          <!-- <img src="assets/latest/pass.svg" alt=""> -->
          <img src="assets/latest/eye2.svg" alt="" *ngIf="!changetype">
          <img src="assets/latest/eye1.svg" alt="" *ngIf="changetype">
        </span>
      </div>
      <div class="for-validation  mb-3 ">
        <div class="for-validation text-danger"
          *ngIf="loginForm.get('password')?.hasError('required') && loginForm.get('password')?.touched">
          *Password is
          required.</div>

      </div>
    </div>

    <div class="d-flex justify-content-between">
      <div class="remb">
        <label  for="">Remember me</label>
        <input type="checkbox" checked="checked" formControlName="checkbox" />
      </div>
      <span class="ml-auto"><a [routerLink]="['/forgot-customer']" class="forgot-pass">Forgot Password?</a></span>
    </div>
    <div class="d-flex justify-content-between" style="align-items: center;">
      <div class="left-wrap">
        <button class="for-login btn text-white btn-block btn-primary" (click)="login()" [disabled]="!loginForm.valid"
          type="submit">Login Now</button>
      </div>
      <div class="right-wrap">
        <span class="d-block text-left my-4 text-muted" [routerLink]="['/signup-customer']"> Don't Have An Account?
          <span class="cols">SignUp Now.</span></span>
      </div>

    </div>
  </form>

</auth-layout>