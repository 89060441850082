<div class="container mb-5 wrapper-content">
    <app-linker-route [headingName]="headingName" [linkerRouteItems]="linkerRouteItems"></app-linker-route>
    <div>
        <span class="dateset">Ordered on {{serviceDetail?.createdAt | date : 'mediumDate' }} </span>
        <span class="line"> </span>
        <span class="orderSet">Order {{serviceDetail?.orderId}}</span>
    </div>
    <div class="right-side" style="width: 100%;">
        <div class="row mt-3">
            <div class="col-md-5">
                <div class="row">
                    <div class="container">
                        <p class="titlem">Product</p>
                        <div class=" mt-4 " *ngFor="let item of productDetails">
                            <div class="row">
                                <div class="col-lg-3 col-md-12 mb-4 mb-lg-0">
                                    <div class="bg-image hover-overlay hover-zoom ripple rounded"
                                        data-ripple-color="light">
                                        <img [src]="serviceImage?.serviceId?.categoryId?.categoryImage"
                                            class="productImageNew" alt="" />
                                        <a>
                                            <div class="mask" style="background-color: rgba(251, 251, 251, 0.2)"></div>
                                        </a>
                                    </div>
                                </div>
                                <div class="col-lg-9 col-md-12 mb-4 mb-lg-0">
                                    <p style="margin: 1px;" class="product">
                                        {{item?.serviceId?.serviceName}}
                                    </p>
                                    <p class="new-pr" style="margin: 1px;">
                                        {{item?.serviceId?.price ? getPrice(item?.serviceId?.price | currency:"ZAR":"R")
                                        :
                                        '--' }}
                                    </p>
                                    <p class="mb-0 deliv">Qty: {{item?.quantity}}</p>
                                </div>

                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div class="col-md-4">
                <div class="row">
                    <div class="container">
                        <p class="titlem">Customer Details</p>
                        <div class="row">
                            <div class="col-lg-12 col-md-12 mb-4 mb-lg-0">
                                <p style="margin: 1px;margin-top: 5px;" class="text-sty text-del">
                                    {{serviceDetail?.shippingFixedAddress?.firstName}}
                                    {{serviceDetail?.shippingFixedAddress?.lastName}}

                                </p>
                                <p style="margin: 1px;margin-top: 3px;" class="text-sty text-del">
                                    {{serviceDetail?.shippingFixedAddress?.email}}


                                </p>
                                <p style="margin: 1px;margin-top: 3px;" class="text-sty text-del">
                                    {{serviceDetail?.shippingFixedAddress?.address}}
                                    {{serviceDetail?.shippingFixedAddress?.addressLine1}}
                                    {{serviceDetail?.shippingFixedAddress?.addressLine2}},
                                </p>

                                <p style="margin: 1px;margin-top: 3px;" class="text-sty text-del">
                                    {{serviceDetail?.shippingFixedAddress?.city}},
                                    {{serviceDetail?.shippingFixedAddress?.state}},{{serviceDetail?.shippingFixedAddress?.country}},
                                    {{serviceDetail?.shippingFixedAddress?.zipCode}}
                                </p>
                                <div class="mt-1">
                                    <span class="phoen"> {{serviceDetail?.shippingFixedAddress?.countryCode}}
                                        {{serviceDetail?.shippingFixedAddress?.mobileNumber}}
                                    </span>
                                </div>
                                <p style="margin: 1px;margin-top: 5px;"><strong class="text-sty"> <a
                                            style="color: rgb(73, 116, 113); cursor: pointer; text-decoration: none;"
                                            [href]="'https://www.google.com/maps/@'+serviceDetail?.shippingFixedAddress?.location?.coordinates[1]+','+serviceDetail?.shippingFixedAddress?.location?.coordinates[0]+',10z'"
                                            target="_blank" rel="noopener noreferrer">
                                            Find Location
                                        </a></strong>
                                </p>
                            </div>

                        </div>
                    </div>
                </div>
            </div>
            <div class="col-md-3">
                <div class="row">
                    <div class="container">
                        <p class="titlemPay">Payment Details</p>
                        <div class="">
                            <div class=" justify-content-between  text-center"
                                style="align-items: center;margin-left: 8px;">

                                <ul>
                                    <li>
                                        <span>Subtotal </span>
                                        <span>
                                            {{serviceDetail?.actualPrice ? getPrice(serviceDetail?.actualPrice |
                                            currency:"ZAR":"R") : "--"}}
                                        </span>
                                    </li>
                                    <li style="margin-top: 5px;">
                                        <span>VAT@15% </span>
                                        <span>
                                            {{serviceDetail?.taxPrice ? getPrice(serviceDetail?.taxPrice |
                                            currency:"ZAR":"R") : "--"}}
                                        </span>
                                    </li>
                                    <li style="margin-top: 5px;">
                                        <span> Grand Total </span>
                                        <span>
                                            {{serviceDetail?.orderPrice ? getPrice(serviceDetail?.orderPrice
                                            | currency:"ZAR":"R") : "--"}}
                                        </span>
                                    </li>


                                </ul>
                            </div>

                        </div>
                    </div>
                </div>
            </div>

        </div>
    </div>

    <!-- <div class="ui-footer row justify-content-between mt-2 text-center" style="align-items: center;">
        <span class="m-auto">
            <button class="butCh" *ngIf="serviceDetail.serviceStatus == 'PENDING' " >Mark as on the way</button>
            <button class="butCh" *ngIf="serviceDetail.serviceStatus == 'ONTHEWAY' " >Send OTP for execute process</button>
            <button class="butCh" *ngIf="serviceDetail.serviceStatus == 'EXECUTION' " >Mark As Done</button>
        </span>
    </div> -->
    <div class="ui-footer row justify-content-between mt-2 text-center" style="align-items: center;">
        <span class="m-auto">
            <!-- <button class="butCh" *ngIf="orderStatus != 'ACCEPTED' " [routerLink]="['/service-order-otp-verification']"
                [queryParams]="{flag:true, _id: _id, orderId: serviceDetail._id}">Mark As Done</button>
         -->
        
                <ng-container *ngIf="orderStatus != 'ACCEPTED' ">
                    <button class="butCh" *ngIf="serviceDetail.serviceStatus == 'PENDING'"
                        (click)="updateServiceOrderStatus('BOOKING_RECEIVED')"
                        [queryParams]="{flag:true, _id: _id, orderId: serviceDetail._id}">Booking Received</button>
                    <button class="butCh" *ngIf="serviceDetail.serviceStatus == 'BOOKING_RECEIVED'"
                        (click)="updateServiceOrderStatus('ONTHEWAY')"
                        [queryParams]="{flag:true, _id: _id, orderId: serviceDetail._id}">Mark as On The Way</button>
                    <button class="butCh" *ngIf="serviceDetail.serviceStatus == 'ONTHEWAY'"
                        (click)="openModalView(serviceDetail._id)"
                        [queryParams]="{flag:true, _id: _id, orderId: serviceDetail._id}">Start service</button>
    
                    <button class="butCh" *ngIf="serviceDetail.serviceStatus == 'EXECUTION'"
                        (click)="openModalView(serviceDetail._id)"
                        [queryParams]="{flag:true, _id: _id, orderId: serviceDetail._id}">Mark as Done</button>
                </ng-container>
        
        
            </span>
    </div>
</div>

<otp-modal *ngIf="openModal" [isService]="true" [orderId]="serviceOrderId"
    (closeModal)="navigateBack();openModal = false"></otp-modal>