import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { AuthService } from 'src/app/provide/auth.service';
import { CommonService } from 'src/app/provide/common.service';
declare var $
@Component({
  selector: 'app-booking-data-view',
  templateUrl: './booking-data-view.component.html',
  styleUrls: ['./booking-data-view.component.scss']
})
export class BookingDataViewComponent implements OnInit {
  data: any
  qtyA: any
  serviceData: any
  idOfAddress: any
  loggedInUserType: any;
  headingName:any ='Booking Detail'
  linkerRouteItems:any =[
    {name:'Home',route: '/home'},
    {name:'Account',route:'/customer-profile'},
    {name:'Bookings',route:'/customer-service-request-list-table'},
    {name:'View',route:'/customer-booking-details-view'}

  ]
  // constructor(private activatedroute: ActivatedRoute, public authService: AuthService, public commonService: CommonService, private router: Router) {
  //   this.activatedroute.queryParams.subscribe((res: any) => {
  //     this.data = JSON.parse(res.data);
      
      
  //     // this.idOfAddress =res.idOfAddress   
  //   })
  // }
  constructor(
    private activatedroute: ActivatedRoute, 
    public authService: AuthService, 
    public commonService: CommonService, 
    private router: Router
  ) {
    // Replace this:
    // this.activatedroute.queryParams.subscribe((res: any) => {
    //   this.data = JSON.parse(res.data);
  
    // With this:
    if (history.state.data) {
      this.data = history.state.data;
    } else {
      // Retrieve data from localStorage if state data is not available
      const storedData = localStorage.getItem('bookingData');
      this.data = storedData ? JSON.parse(storedData) : null;
    }
  }

  ngOnInit(): void {
    this.authService.loggedInUserType.subscribe((res: any) => {
      this.loggedInUserType = res

    })
    this.loggedInUserType = this.authService.checkUserTypeLoggedIn()
    this.viewOpenOrder()
  }

  viewOrderData: any = []
  orderDataDetials = {
    booking : false,
    ontheway : false,
    execution : false,
    completed : false
  }
  feedBackData: any
  isFeedbackDataSubmited = false
  viewOpenOrder() {
    let url = `order/v2/viewOrder?orderId=${this.data._id}`
    this.commonService.showSpinner();
    this.commonService.getApi(url, 1).subscribe((res: any) => {
      if (res["responseCode"] == 200) {
        this.commonService.hideSpinner();
        let result = res.result
        this.orderDataDetials= res.result
       if(result.serviceStatus == 'BOOKING_RECEIVED'){
        this.orderDataDetials.booking = true
       }
       
       if(result.serviceStatus == 'ONTHEWAY'){
        this.orderDataDetials.booking = true
        this.orderDataDetials.ontheway = true
       }
       if(result.serviceStatus == 'EXECUTION'){
        this.orderDataDetials.booking = true
        this.orderDataDetials.ontheway = true
        this.orderDataDetials.execution = true
       }
       if(result.serviceStatus == 'COMPLETED'){
        this.orderDataDetials.booking = true
        this.orderDataDetials.ontheway = true
        this.orderDataDetials.execution = true
        this.orderDataDetials.completed = true
       }
      //  if (result.feedBack && result.feedBack.length) {
      //   this.feedBackData = [
      //     {
      //       title: 'Service Feedback',
      //       type: 'ORDER'
      //     },
      //   ]
      //   let i = 0
      //   for (const item of res.result.feedBack) {
      //     this.feedBackData[i].review = item.review
      //     this.feedBackData[i].rating = item.rating
      //     i++
      //   }
      //   this.isFeedbackDataSubmited = true
      // }
      // else {
      //   this.feedBackData = [
      //     {
      //       title: 'Order Feedback',
      //       type: 'ORDER',
      //       rating: 0
      //     },
      //   ]
      // }

      } else {
        this.commonService.hideSpinner();
        this.commonService.errorToast(res["responseMessage"])
      }
    }, (err) => {
      this.commonService.hideSpinner();
      this.commonService.errorToast("Server error")
    });
  }
  getPrice(e: any) {
    let a = e.replace(/,/g, ' ')
    return a.replace(/R/g, 'R ')
  }
  date: any = Date

  getAddedDate(createdAt: string, days: number) {
    let date = new Date(createdAt).setDate(new Date(createdAt).getDate() + 3);
    return date
  }

  
}
