import { Component, OnInit, ViewChild } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { AbstractControl, FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { GoogleMap } from '@angular/google-maps';
import { CommonService } from 'src/app/provide/common.service';
import * as contryList from '../../../../assets/countrycodeexport'
@Component({
  selector: 'app-contactus',
  templateUrl: './contactus.component.html',
  styleUrls: ['./contactus.component.scss'],
  styles: [
    `p {
      margin: 0;
      font-size: 16px;
  }`  ]
})
export class ContactusComponent implements OnInit {
  // map: any;
  headingName: any = 'Contact Us'
  linkerRouteItems: any = [
    { name: 'Bazarhub', route: '/home' },
    { name: 'Contact', route: '/contactUs' }
  ]


  @ViewChild('map') mapElement: any;
  @ViewChild(GoogleMap, { static: true }) map: GoogleMap | undefined;
  contactUs!: FormGroup
  products: any[] = [];
  lat: any;
  lng: any;
  result: any;
  countryCode = new FormControl('+27');
  pageName: any;
  marker: any = [];
  center: any
  zoom = 14
  options: google.maps.MapOptions = {
    mapTypeId: 'hybrid',
    zoomControl: false,
    scrollwheel: false,
    disableDoubleClickZoom: true,
  }
  "status": "ACTIVE"


  // ========

  termsData: any;
  staticContentType: any;
  categoryListArray: any;

  constructor(private http: HttpClient, public commonService: CommonService, private router: Router,private fb: FormBuilder) {
    let me = this
    window.navigator.geolocation.getCurrentPosition(function (position) {

      me.lat = position.coords.latitude
      me.lng = position.coords.longitude

    })
    this.http.get("assets/countrycode.json").subscribe((data: any) => {
      this.products = data.result;
    })
  }

  ngOnInit(): void {
    window.scrollTo(0, 0);
    this.contactUs = this.fb.group({
      "fName" : ['', [Validators.required, Validators.minLength(2), Validators.maxLength(32)]],
      "lName" : ['', [Validators.required, Validators.minLength(2), Validators.maxLength(32)]],
      "email" :  ['', [Validators.required, Validators.pattern(/^([a-zA-Z0-9_\-\.]+)@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.)|(([a-zA-Z0-9\-]+\.)+))([a-zA-Z]{2,10}|[0-9]{1,3})(\]?)$/i)]],
      Phone : ['', [Validators.required, Validators.pattern(/^[^0][0-9]*$/), Validators.minLength(8), Validators.maxLength(15)]],
      countryCode : ['+27', [Validators.required, this.countryCodeValidator]],
      Country : '',
      Comment : ['', [Validators.required]],
    });

 
  


   

    this.getcontent();
    // this.getcontents();


  }


  countryCodeValidator = (control: AbstractControl): { [key: string]: boolean } | null => {
    if (!this.products || this.products.length === 0) {
      return null; // Return null if products are not available for validation
    }
    if (control.value && this.products.length && !this.products.some(product => product.phonecode === control.value)) {
      return { invalidCountryCode: true };
    }
    return null;
}



  contactUss() {
    let url = "static/addReachUs"
    var data = {

      firstName: this.contactUs.value.fName,
      lastName: this.contactUs.value.lName,
      email: this.contactUs.value.email,
      mobileNumber: this.contactUs.value.Phone,
      comment: this.contactUs.value.Comment,
      country: this.contactUs.value.Country,
      countryCode: this.contactUs.value.countryCode,

    }
    this.commonService.showSpinner()
    this.commonService.postApi(url, data, 0).subscribe((res: any) => {
      if (res['responseCode'] == 200) {
        // this.getcontent();
        this.result = res["result"]["description"]
        this.pageName = res["result"]["type"]

        // this.pageName = String(this.pageName).split(/(?=[A-Z])/).join(" ")
        // this.commonService.hideSpinner()
        this.commonService.successToast(res.responseMessage);
        // alert('“Thanks for connecting with us.”')
        this.commonService.hideSpinner();
        this.router.navigate(['/home'])
      }
      else {
        this.commonService.hideSpinner();
        this.commonService.errorToast(res.responseMessage)
      }
    })

  }

  applyStyle() {
    const parentElement = document.getElementById('para_alex');
    // Get all child <p> elements inside the parent element
    const paragraphs = parentElement.getElementsByTagName('p');
    for (let i = 0; i < paragraphs.length; i++) {
      paragraphs[i].style.margin = '0';
    }
  }

  getmapdetails(lat: any, long: any) {



    this.center = new google.maps.LatLng(lat, long),

      this.marker = new google.maps.Marker({
        position: new google.maps.LatLng(lat, long),
        // map: this.map,
        // animation: google.maps.Animation.BOUNCE,

        icon:// 'https://icon-library.com/images/current-location-icon/current-location-icon-8.jpg'
        {
          url: 'assets/img/google-map.png',
          size: new google.maps.Size(32, 45),
          scaledSize: new google.maps.Size(32, 45),
          anchor: new google.maps.Point(0, 50),
        }
      })
    this.marker.setMap()
  }



  // get static content detail by id
  getcontent() {

    this.commonService.getApi('static/viewStaticContent?type=contactUs', 0).subscribe((res: any) => {
      this.commonService.showSpinner()
      if (res['responseCode'] == 200) {
        this.termsData = res['result']
        // this.getmapdetails(res.result.location.coordinates[0], res.result.location.coordinates[1])
        this.getmapdetails(this.lat, this.lng)

        setTimeout(() => {
          this.applyStyle()

        }, 100);
        this.commonService.hideSpinner();
        // this.commonService.successToast(res.responseMessage);
      } else {
        this.commonService.hideSpinner();
        this.commonService.errorToast(res.responseMessage)
      }
    }, err => {

      this.commonService.warningToast(err['error']['responseMessage'])
    }
    )
  }



  preventSpace(event: any) {
    if ((event.charCode == 32 || event.charCode == 64) && !event.target.value) {
      event.preventDefault();
    }
  }

  NumOnly(event: any) {
    let Numpattern = /^([0-9])*$/;
    let resultNum = Numpattern.test(event.key);
    return resultNum;

  }
  restirictChar(event): boolean {
    const charCode = event.which ? event.which : event.keyCode;
    if (charCode > 31 && (charCode < 48 || charCode > 57)) {
      return true;
    }
    return false;
  }

  totalItems: any
  getcontents($event: any) {

    this.commonService.getApi('user/listCountry', 0).subscribe((res: any) => {
      this.commonService.showSpinner()
      if (res['responseCode'] == 200) {
        // this.categoryListArray = res['result']

        this.totalItems = res['result']
        this.commonService.hideSpinner();
        // this.commonService.successToast(res.responseMessage);
      } else {
        this.categoryListArray = []
        this.commonService.hideSpinner();
        this.commonService.errorToast(res.responseMessage)
      }
    }, err => {

      this.commonService.warningToast(err['error']['responseMessage'])
    }
    )
  }

}
