<div >
    <div>
        <div class="bannerContent position-relative">
            <i class="fa fa-pencil" *ngIf="showEdit" style="color: rgb(73, 116, 113); cursor: pointer;"
            (click)="onEdit(data?._id)"></i>
            <div class="card innerDiv" (click)="navigateToViewProduct(data?._id);">
                <div class="image">
                    <img class="img" [hidden]="!isLoaded" (load)="onImageLoad()" [src]="data?.categoryImage" alt="service provider">
                    <ngx-skeleton-loader *ngIf="!isLoaded" [theme]="{width: '235px',
                    height: '270px',
                    position: 'relative'}" count="1"></ngx-skeleton-loader>
                </div>
                <!-- <div class="image">
                    <img class="img" [src]="data?.categoryImage" [hidden]="!isLoaded" (load)="onImageLoad()" alt="categoryImage">
                    <ngx-skeleton-loader *ngIf="!isLoaded" [theme]="{width: '235px',
                    height: '270px',
                    position: 'relative', margin:0}" count="1"></ngx-skeleton-loader>
                </div> -->
                <div class="mt-1">
                    <p class="description">{{data?.productName || data?.categoryName}}</p>
                </div>
                <div style="margin-top: -8px;" *ngIf="data?.priceSizeDetails">
                    <div class="row">
                        <div class="col-12 p-0 m-0">
                            <p class="price" > 
                                <span style="text-decoration: line-through;" *ngIf="data.discount">
                                    {{data?.priceSizeDetails && data?.priceSizeDetails[0]?.price &&
                                        getPrice(data?.priceSizeDetails[0]?.price | currency:"ZAR":"R")}}
                            </span>
                            <span class="price-color ml-0"
                                    >{{data?.priceSizeDetails && data?.priceSizeDetails[0]?.price &&
                                        getPrice(data?.priceSizeDetails[0]?.price | currency:"ZAR":"R")}}</span> </p>
                        </div>
                    </div>

                     <div class="row" style="margin-top: -8px;" *ngIf="data.discount">
                        <div class="col-12 p-0 m-0">
                            <p class="dealPrice" style="font-size: 16px;"> Discount : <span class="newn">
                                {{data.discount}}% off
                            </span>
                            </p>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>