<div class="container  fw-b ">
  <app-linker-route [headingName]="headingName" [linkerRouteItems]="linkerRouteItems"></app-linker-route>
</div>


<div class="container mt-2 ss mb-5">
  <!-- <span routerLink="/home" class="circle-arrow"><i class="fa fa-arrow-left tittr" style=" cursor: pointer;"></i></span> -->
  <div class="">
    <!-- <div class="text-center" style="padding-top: 20px;">
      <h2 class=" pro-2">Product Details</h2>
    </div> -->

    <div class="row mt-3 mb-5">
      <div class="col-lg-5 col-md-5 col-sm-5">
        <div class="row">
          <span class="imgSpan">
            <img class="image-custom qwqwqw" [src]="UserDetailsData?.profilePic" [hidden]="!isLoaded"
              (load)="onLoadImage()" />


            <ngx-skeleton-loader *ngIf="!isLoaded" [theme]="{width: '456px', height:'405px',
                    position: 'relative'}" count="1"></ngx-skeleton-loader>

          </span>
        </div>

      </div>
      <div class="descp_box col-lg-7 col-md-7 col-sm-7">
        <div class="row">
          <div class="col-12 pl-0">
            <p class="prdct-home">
              {{ UserDetailsData?.ownerFirstName }}
              {{ UserDetailsData?.ownerLastName }}
            </p>

            <p>Please Choose booking You Want From Us:</p>
          </div>
        </div>
        <div class="" style="margin-top: 1em">
          <h3>{{ categoryName }}</h3>
          <div id="accordion" *ngFor="let item of serviceDetailsArray; let i = index">
            <mat-accordion>
              <mat-expansion-panel (opened)="panelOpenState = true" (closed)="panelOpenState = false" *ngIf="(item?.subCategory?.subCategoryName != 'eXobe Fulfilment') && 
              (item?.subCategory?.subCategoryName.trim().toLowerCase() != 'exobe delivery') && (item?.subCategory?.subCategoryName.trim().toLowerCase() != 'exobe pickup') " >
                <mat-expansion-panel-header class="faq-qsn">
                  <mat-panel-title class="gg" style="color: rgb(73, 116, 113)">
                    {{ item?.subCategory?.subCategoryName || "---" }}
                  </mat-panel-title>
                </mat-expansion-panel-header>
                <div class="d-flex justify-content-between" *ngFor="let data of item?.serviceArray; let j = index"
                  style="text-align: center; align-items: center">
                  <div class="custom-control custom-checkbox my-1 mr-sm-2" style="padding: 0px !important">
                    <input type="checkbox" class="custom-control-input"
                      (input)="getSelectedService($event, item, data, i)"
                      [attr.id]="'customControlInline' + i + '' + j" />
                    <label class="custom-control-label" [attr.for]="'customControlInline' + i + '' + j">{{
                      data?.serviceName }}</label>
                  </div>
                  <div style="    display: flex;
                  align-items: center;">
                  <ng-container *ngIf="data?.dealDiscount">
                    <p class="mb-0" style="text-decoration:line-through ;">
                      {{
                      data?.price &&
                      getPrice(data?.price | currency : "ZAR" : "R")
                      }}
                    </p>&nbsp;
                    <p class="mb-0 ner">
                      {{
                      data?.dealPrice &&
                      getPrice(data?.dealPrice | currency : "ZAR" : "R")
                      }}
                    </p>&nbsp;
                    <p class="mb-0 ner">
                      {{data?.dealDiscount}}% Off
                    </p>
                  </ng-container>

                  <ng-container *ngIf="!data?.dealDiscount || !data?.isCampaignActive">
                    <p class="mb-0" >
                      {{
                      data?.price &&
                      getPrice(data?.price | currency : "ZAR" : "R")
                      }}
                    </p>&nbsp;
                    
                  </ng-container>
                  <!-- <ng-container *ngIf="data?.isCampaignActive">
                    <p class="mb-0" style="text-decoration:line-through ;">
                      {{
                        data?.price &&
                      getPrice(  data?.price | currency : "ZAR" : "R")
                      }}
                    </p>&nbsp;
                    <p class="mb-0 ner">
                      {{
                        data?.campainId?.serviceDiscountedPrice &&
                      getPrice(data?.campainId?.serviceDiscountedPrice | currency : "ZAR" : "R")
                      }}
                    </p>&nbsp;
                  </ng-container> -->
                  
                   
                  </div>
                  <!-- <p style="color: rgb(73, 116, 113)">Campaign Ends In </p>
                  <div
                        class="end-pro-time1 text-center mb-2"
                        *ngIf="getCountDownOld(i, data?.campainId?.endDate)"
                      >
                        {{ getCountDownOld(i,data?.campainId?.endDate) }}
                      </div> -->
                </div>
               
              </mat-expansion-panel>
            </mat-accordion>
          </div>
        </div>

        <div class="mt-4">
          <h1 class="text-center desc">Description</h1>
          <p class="mt-4 text-left descrip">
            {{ serviceDetailsArray[0]?.subCategory?.categoryId?.description }}
          </p>
        </div>
        <div class="mt-4">
          <div class="right-totalamout">
            <span *ngIf="isLoggedIn">

              <button class="butCh" (click)="navigateToserviceAvailableAdd()">
                Proceed to Book
              </button>
            </span>
            <span *ngIf="!isLoggedIn"><button class="butCh" [routerLink]="['/login']">
                Proceed to Book
              </button></span>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>