import { Component, OnDestroy, OnInit } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { CommonService } from 'src/app/provide/common.service';
import { StateManagementService } from 'src/app/provide/state-management.service';
declare var $
@Component({
  selector: 'app-homepage-retailer',
  templateUrl: './homepage-retailer.component.html',
  styleUrls: ['./homepage-retailer.component.scss']
})
export class HomepageRetailerComponent implements OnInit, OnDestroy {
  homepage!: FormGroup;
  itemPerPage = 4;
  currentPage = 1;
  totalItems: any;
  termsData: any;
  categoryListArrayNew: any;
  _id: any
  a: any;
  totalPage: number = 0;
  // latitude and longitude property
  latitude: any;
  longitude: any;
  ipAddress: string;
  serviceDetailDeals : any = new Array(1)
  isLoaded : any = new Array(4)
  // totalItems: any;
  // categoryListArray:[];
  // categoryListArray: never[];
  constructor(private activatedroute: ActivatedRoute, public commonService: CommonService, private router: Router, public stateManagement: StateManagementService) {
    this.activatedroute.queryParams.subscribe((res: any) => {
      this._id = res._id;
    })

  }
  isLogin: any
  ngOnInit(): void {
    window.scrollTo(0, 0)
    this.commonService.profileUpdateItem.next(true)
    // this.homePage();
    // this.getCategoryList();
    this.homepage = new FormGroup({
      email: new FormControl('', [
        Validators.required,
        Validators.pattern(/^([a-zA-Z0-9_\-\.]+)@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.)|(([a-zA-Z0-9\-]+\.)+))([a-zA-Z]{2,10}|[0-9]{1,3})(\]?)$/i)
      ]),
    })
    this.isLogin = localStorage.getItem('token')
    this.getBannerProductList()
    this.stateManagement.categoryRetailer.subscribe((res) => {
      if (res) {
        this.categoryListArray = res
      }
      else {
        this.getCategoryLists()

      }
    })
    this.stateManagement.getProductRetailer.subscribe((res) => {
      if (res) {
        this.bannerData = res
        $(document).ready(function () {
          $('.banner_slider').slick({
            dots: false,
            arrow: true,
            infinite: false,
            speed: 300,
            slidesToShow: 5,
            slidesToScroll: 1,
            responsive: [
              {
                breakpoint: 1024,
                settings: {
                  slidesToShow: 3,
                  slidesToScroll: 3,
                  infinite: true,
                  dots: false
                }
              },
              {
                breakpoint: 600,
                settings: {
                  slidesToShow: 2,
                  slidesToScroll: 2
                }
              },
              {
                breakpoint: 425,
                settings: {
                  slidesToShow: 2,
                  slidesToScroll: 2
                }
              },
              {
                breakpoint: 350,
                settings: {
                  slidesToShow: 2,
                  slidesToScroll: 2
                }
              }
              // You can unslick at a given breakpoint now by adding:
              // settings: "unslick"
              // instead of a settings object
            ]
          });
        });
      }
      else {
        this.bannerData = []
        this.getLocation()

      }
    })
    this.stateManagement.getDealRetailer.subscribe((res) => {
      if (res) {
        this.serviceDetail = res
        $(document).ready(function () {
          $('.deal_slider').slick({
            dots: false,
            arrow: true,
            infinite: false,
            speed: 300,
            slidesToShow: 5,
            slidesToScroll: 1,
            responsive: [
              {
                breakpoint: 1024,
                settings: {
                  slidesToShow: 3,
                  slidesToScroll: 3,
                  infinite: true,
                  dots: false
                }
              },
              {
                breakpoint: 600,
                settings: {
                  slidesToShow: 2,
                  slidesToScroll: 2
                }
              },
              {
                breakpoint: 425,
                settings: {
                  slidesToShow: 2,
                  slidesToScroll: 2
                }
              },
              {
                breakpoint: 350,
                settings: {
                  slidesToShow: 2,
                  slidesToScroll: 2
                }
              }
              // You can unslick at a given breakpoint now by adding:
              // settings: "unslick"
              // instead of a settings object
            ]
          });
        });
      }

    })

    this.stateManagement.getserviceOnBooking.subscribe((res) => {
      if (res) {
        this.serviceDetailDeals = res
        $(document).ready(function () {
          $('.deal_slider_bookings').slick({
            dots: false,
            arrow: true,
            infinite: false,
            speed: 300,
            slidesToShow: 5,
            slidesToScroll: 1,
            responsive: [
              {
                breakpoint: 1024,
                settings: {
                  slidesToShow: 3,
                  slidesToScroll: 3,
                  infinite: true,
                  dots: false
                }
              },
              {
                breakpoint: 600,
                settings: {
                  slidesToShow: 2,
                  slidesToScroll: 2
                }
              },
              {
                breakpoint: 425,
                settings: {
                  slidesToShow: 2,
                  slidesToScroll: 2
                }
              },
              {
                breakpoint: 350,
                settings: {
                  slidesToShow: 2,
                  slidesToScroll: 2
                }
              }
              // You can unslick at a given breakpoint now by adding:
              // settings: "unslick"
              // instead of a settings object
            ]
          });
        });
        for (let index = 0; index < this.serviceDetailDeals.length; index++) {
          this.getCoundownNew(this.serviceDetailDeals[index]['dealEndTime'], index);
        }
      }

    })
    this.stateManagement.getServiceRetailer.subscribe((res) => {
      if (res) {
        this.serviceData = res
        $(document).ready(function () {
          $('.service_slider').slick({
            dots: false,
            arrow: true,
            infinite: false,
            speed: 300,
            slidesToShow: 3,
            slidesToScroll: 1,
            responsive: [
              {
                breakpoint: 1024,
                settings: {
                  slidesToShow: 3,
                  slidesToScroll: 3,
                  infinite: true,
                  dots: false
                }
              },
              {
                breakpoint: 600,
                settings: {
                  slidesToShow: 2,
                  slidesToScroll: 2
                }
              },
              {
                breakpoint: 425,
                settings: {
                  slidesToShow: 2,
                  slidesToScroll: 2
                }
              },
              {
                breakpoint: 350,
                settings: {
                  slidesToShow: 2,
                  slidesToScroll: 2
                }
              }
              // You can unslick at a given breakpoint now by adding:
              // settings: "unslick"
              // instead of a settings object
            ]
          });
        });
      }
      else {
        this.serviceData = []
        this.getServiceProduct()

      }
    })
    // this.getLocation();
    // this.getBanner()
    // this.getServiceProduct()
    navigator.geolocation.getCurrentPosition(function(position) {
      // alert('allow');
  }, function() {
      alert('You need to enable geolocation services in your browser to use this feature.');
  });
  this.getPosition().then(pos=>

    {

    localStorage.setItem('location',JSON.stringify(pos))

  });
  // this.getLocation();

  }
  getPosition(): Promise<any> {
    return new Promise((resolve, reject) => {

      navigator.geolocation.getCurrentPosition(resp => {

        resolve({ lng: resp.coords.longitude, lat: resp.coords.latitude });
      },
        err => {
          reject(err);
        });
    });

  }
  // directPage(url){
  //   if(url){
  //     window.open(url,'_blank')
  //   }

  // }
  getLocation() {
    if (navigator.geolocation) {
      navigator.geolocation.getCurrentPosition((position) => {
        if (position) {

          this.latitude = position.coords.latitude;
          this.longitude = position.coords.longitude;


          this.getBanner()
          this.getproduct()
          this.getBannerBooking()

        }
      },
        (error) => console.log(error));
    } else {
      alert("Geolocation is not supported by this browser.");
    }
  }

  preventSpace(event: any) {
    if ((event.charCode == 32 || event.charCode == 64) && !event.target.value) {
      event.preventDefault();
    }
  }

  loggedin() {
    return localStorage.getItem('token');
  }

  onLogout() {
    localStorage.removeItem('token');
  }
  // ========================




  getcontent() {

    this.commonService.getApi('user/homePageForWholeSaler', 1).subscribe((res: any) => {
      this.commonService.showSpinner()

      if (res['responseCode'] == 200) {

        this.termsData = res['result']['dealDetails']['docs']
        this.totalItems = res.result.total

        this.commonService.hideSpinner();
        // this.commonService.successToast(res.responseMessage);
      } else {
        this.termsData = []
        this.totalItems = 0
        this.commonService.hideSpinner();
        this.commonService.errorToast(res.responseMessage)
      }
    }, err => {
      if (err.responseCode == 404) {
        this.termsData = []
        this.totalItems = 0
        // this.commonService.warningToast(err['error']['responseMessage'])


      }
    }
    )
  }



  pagination(event: number) {

    this.currentPage = event;
    this.getcontent()
  }



  // ============categay list===========
  categoryListArray: any = new Array(8)

  getCategoryLists() {
    let apiReqUrl: any = `admin/listCategory`
    // this.commonService.showSpinner();
    let apiReqData: any = {
      // page: String(this.currentPage),
      // limit: String(this.itemPerPage)
    }
    // remove empty keys from request body
    // apiReqData = this.commonService['removeEmptyKey'](apiReqData)
    apiReqData = this.commonService.removeEmptyKey(apiReqData)
    let a = this.commonService.isLoggedIn() ? 1: 0
    this.commonService.postApi(apiReqUrl, apiReqData, a).subscribe((res: any) => {

      if (res.responseCode == 200) {
        this.categoryListArray = res.result.docs ? res.result.docs : '';


        this.totalItems = res.result.total
        this.commonService.hideSpinner();
        // this.commonService.successToast(res.responseMessage);
      } else {
        this.categoryListArray = []
        this.totalItems = 0
        this.commonService.hideSpinner();
        this.commonService.errorToast(res.responseMessage)
      }
    })
  }


  getPrice(e: any) {

    let a = e.replace(/,/g, ' ')
    return a.replace(/R/g, 'R ')
  }




  forID(_id: any) {
    this.router.navigate(['/deal-details'], { queryParams: { _id: _id } })
  }



  // knoemore
  descrition: any

  // ====================================================save button================================
  getDeals() {
    let data = {



      email: this.homepage.value.email,
      descrition: this.homepage.value.descrition,
      // dealDiscount: this.addDeals.value.discount,
      // dealStartTime: this.addDeals.value.dealStartTime,
      // dealEndTime: this.addDeals.value.dealEndTime,
      // dealsFor: "RETAILER"

    }

    let apiReqUrl: any = `user/stepsForUser`
    this.commonService.showSpinner();
    this.commonService.postApi(apiReqUrl, data, 0).subscribe((res: any) => {
      if (res.responseCode == 200) {
        this.commonService.hideSpinner();
        this.commonService.successToast(res.responseMessage);
        this.homepage.reset()
        // this.router.navigate(['/list-deals'])
        // localStorage.setItem('token', res.result.token)
      } else {
        this.commonService.hideSpinner();
        this.commonService.errorToast(res.responseMessage)
      }
    })
  }
  viewParticularProducts(productId: any) {
    this.router.navigate(['/list-product'], { queryParams: { _id: productId } })
  }
  onSearch(id?: any) {
    // this.showSearchType = false;
    localStorage.setItem("category", id)
    this.router.navigate(['/search-product'])
    this.commonService.onFirstComponentButtonClick();
    // this.router.navigate(['/search-product'], { queryParams: { _id: id,search:search} })
  }
  bannerData: any = new Array(1)
  serviceDetail: any = new Array(1)
  getBanner() {
    
    let url = `deal/listDealByLocation`
    // let url =`product/listProductBySortion`
    let data = {
      "dealType": "PRODUCT",
      "userType": "WHOLE_SALER",
      'lng': this.longitude,
      'lat': this.latitude,
      'page': this.currentPage,
      'limit': 10
    }

    this.commonService.removeEmptyKey(data)
    // this.commonService.showSpinner()
    let a = this.commonService.isLoggedIn() ? 1 : 0
    this.commonService.postApi(url, data, a).subscribe((res: any) => {
      if (res.responseCode == 200) {

        this.serviceDetail = res.result.docs
        if (this.timerInterval) {
          clearInterval(this.timerInterval);
          this.countDown = [];
        }
        this.totalItems = res.result.totalPages
        for (let index = 0; index < this.serviceDetail.length; index++) {
          this.getCoundown(this.serviceDetail[index]['dealEndTime'], index);
        }
        // this.dealData = res["result"]["docs"];
        this.commonService.hideSpinner()
        $(document).ready(function () {
          $('.deal_slider').slick({
            dots: false,
            arrow: true,
            infinite: false,
            speed: 300,
            slidesToShow: 5,
            slidesToScroll: 1,
            responsive: [
              {
                breakpoint: 1024,
                settings: {
                  slidesToShow: 3,
                  slidesToScroll: 3,
                  infinite: true,
                  dots: false
                }
              },
              {
                breakpoint: 600,
                settings: {
                  slidesToShow: 2,
                  slidesToScroll: 2
                }
              },
              {
                breakpoint: 425,
                settings: {
                  slidesToShow: 2,
                  slidesToScroll: 2
                }
              },
              {
                breakpoint: 350,
                settings: {
                  slidesToShow: 2,
                  slidesToScroll: 2
                }
              }
              // You can unslick at a given breakpoint now by adding:
              // settings: "unslick"
              // instead of a settings object
            ]
          });
        });
        this.commonService.hideSpinner()
      }
      else{
        this.serviceDetail = new Array(0)
      }
    },(err)=>{
      this.serviceDetail = new Array(0)
      
    })
  }

  myTimer() { }
  // getDealDescription
  countDown: any = [];
  timerInterval: any = [];
  new: any
  getCoundown(date: Date, i: number) {
    var countDownDate = new Date(date).getTime();
    let me = this;
    // Update the count down every 1 second

    this.timerInterval[i] = setInterval(() => {
      if (true) {
        // Get today's date and time
        let now = new Date().getTime();

        // Find the distance between now and the count down date
        let distance = countDownDate - now;

        // Time calculations for days, hours, minutes and seconds
        let days = Math.floor(distance / (1000 * 60 * 60 * 24));
        let hours = Math.floor(
          (distance % (1000 * 60 * 60 * 24)) / (1000 * 60 * 60)
        );
        let minutes = Math.floor((distance % (1000 * 60 * 60)) / (1000 * 60));
        let seconds = Math.floor((distance % (1000 * 60)) / 1000);

        // Output the result in an element with id="demo"
        me.countDown[i] =
        days + 'D:' + hours + 'H:' + minutes + 'M:' + seconds + 'S';        // If the count down is over, write some text
        if (distance < 0) {
          clearInterval(me.timerInterval);
          me.countDown[i] = 'EXPIRED';
        }
      }

      // me.countDown[i] = mm
    }, 1000);
  }
  getproduct() {
    let url = `product/listProductBySortion`
    let data = {
      // "dealType" :  "PRODUCT",
      "userType": "WHOLE_SALER",
      'lng': this.longitude,
      'lat': this.latitude,
      'page': this.currentPage,
      'limit': 10
    }

    // this.commonService.showSpinner()
    let a = this.commonService.isLoggedIn() ? 1 : 0
    this.commonService.postApi(url, data, a).subscribe((res: any) => {
      if (res.responseCode == 200) {
        this.bannerData = res["result"]["docs"];
        this.commonService.hideSpinner()
        $(document).ready(function () {
          $('.banner_slider').slick({
            dots: false,
            arrow: true,
            infinite: false,
            speed: 300,
            slidesToShow: 5,
            slidesToScroll: 1,
            responsive: [
              {
                breakpoint: 1024,
                settings: {
                  slidesToShow: 3,
                  slidesToScroll: 3,
                  infinite: true,
                  dots: false
                }
              },
              {
                breakpoint: 600,
                settings: {
                  slidesToShow: 2,
                  slidesToScroll: 2
                }
              },
              {
                breakpoint: 425,
                settings: {
                  slidesToShow: 2,
                  slidesToScroll: 2
                }
              },
              {
                breakpoint: 350,
                settings: {
                  slidesToShow: 2,
                  slidesToScroll: 2
                }
              }
              // You can unslick at a given breakpoint now by adding:
              // settings: "unslick"
              // instead of a settings object
            ]
          });
        });
        this.commonService.hideSpinner()
      }
      else{
        this.bannerData = []
      }
    },(err)=>{
      this.bannerData = []
    })
  }
  getBannerBooking() {

    let url = `deal/dealsListOnCategory`
    // let url =`product/listProductBySortion`
    // let data = {
    //   "dealType": "PRODUCT",
    //   "userType": "RETAILER",
    //   'lng': this.longitude,
    //   'lat': this.latitude,
    //   'page': this.currentPage,
    //   'limit': 10
    // }

    // this.commonService.removeEmptyKey(data)
    // this.commonService.showSpinner()
    this.commonService.getApi(url, 0).subscribe((res: any) => {
      if (res.responseCode == 200) {

        if (this.timerInterval) {
          clearInterval(this.timerInterval);
          this.countDown = [];
        }
        this.serviceDetailDeals = res.result
        this.totalItems = res.result.totalPages
        for (let index = 0; index < this.serviceDetailDeals.length; index++) {
          this.getCoundownNew(this.serviceDetailDeals[index]['dealEndTime'], index);
        }
        // this.dealData = res["result"]["docs"];
        this.commonService.hideSpinner()
        $(document).ready(function () {
          $('.deal_slider_bookings').slick({
            dots: false,
            arrow: true,
            infinite: false,
            speed: 300,
            slidesToShow: 5,
            slidesToScroll: 1,
            responsive: [
              {
                breakpoint: 1024,
                settings: {
                  slidesToShow: 3,
                  slidesToScroll: 3,
                  infinite: true,
                  dots: false
                }
              },
              {
                breakpoint: 600,
                settings: {
                  slidesToShow: 2,
                  slidesToScroll: 2
                }
              },
              {
                breakpoint: 425,
                settings: {
                  slidesToShow: 2,
                  slidesToScroll: 2
                }
              },
              {
                breakpoint: 350,
                settings: {
                  slidesToShow: 2,
                  slidesToScroll: 2
                }
              }
              // You can unslick at a given breakpoint now by adding:
              // settings: "unslick"
              // instead of a settings object
            ]
          });
        });
        this.commonService.hideSpinner()
      }
      else{
        this.serviceDetailDeals = []
      }
    },(err)=>{
      this.serviceDetailDeals = []
    })
  }
  serviceData: any = new Array(1)
  getServiceProduct() {
    let url = `service/listCategoryService`
    let data = {
      // "dealType" :  "PRODUCT",
      'page': this.currentPage,
      'limit': 10
    }
    let a = this.commonService.isLoggedIn() ? 1 : 0

    this.commonService.postApi(url, data, a).subscribe((res: any) => {
      if (res.responseCode == 200) {
        this.serviceData = res["result"]["docs"];
        $(document).ready(function () {
          $('.service_slider').slick({
            dots: false,
            arrow: true,
            infinite: false,
            speed: 300,
            slidesToShow: 3,
            slidesToScroll: 1,
            responsive: [
              {
                breakpoint: 1024,
                settings: {
                  slidesToShow: 3,
                  slidesToScroll: 3,
                  infinite: true,
                  dots: false
                }
              },
              {
                breakpoint: 600,
                settings: {
                  slidesToShow: 2,
                  slidesToScroll: 2
                }
              },
              {
                breakpoint: 425,
                settings: {
                  slidesToShow: 2,
                  slidesToScroll: 2
                }
              },
              {
                breakpoint: 350,
                settings: {
                  slidesToShow: 2,
                  slidesToScroll: 2
                }
              }
              // You can unslick at a given breakpoint now by adding:
              // settings: "unslick"
              // instead of a settings object
            ]
          });
        });
      }
    })
  }

  navigateToViewProduct(_id) {
    this.router.navigate(['/product-view-customer'], { queryParams: { _id: _id } })
  }
  navigateToViewService(_id, catName,desc) {
    this.router.navigate(['/customer-service-available'], { queryParams: { _id: _id, catName: catName , desc:desc} })
  }
  navigateToViewProducts(_id, categoryName) {
    this.router.navigate(['/retailer-category-list-view'], { queryParams: { _id: _id, categoryName: categoryName } })
  }
  countDownNew: any = [];
  timerIntervalNew: any = [];
  getCoundownNew(date: Date, i: number) {
    var countDownDate = new Date(date).getTime();
    let me = this;
    // Update the count down every 1 second

    this.timerIntervalNew[i] = setInterval(() => {
      if (true) {
        // Get today's date and time
        let now = new Date().getTime();

        // Find the distance between now and the count down date
        let distance = countDownDate - now;

        // Time calculations for days, hours, minutes and seconds
        let days = Math.floor(distance / (1000 * 60 * 60 * 24));
        let hours = Math.floor(
          (distance % (1000 * 60 * 60 * 24)) / (1000 * 60 * 60)
        );
        let minutes = Math.floor((distance % (1000 * 60 * 60)) / (1000 * 60));
        let seconds = Math.floor((distance % (1000 * 60)) / 1000);

        // Output the result in an element with id="demo"
        me.countDownNew[i] =
        days + 'D:' + hours + 'H:' + minutes + 'M:' + seconds + 'S';        // If the count down is over, write some text
        if (distance < 0) {
          clearInterval(me.timerIntervalNew);
          me.countDownNew[i] = 'EXPIRED';
        }
      }

      // me.countDown[i] = mm
    }, 1000);
  }
  dealVIEw(_id) {
    this.router.navigate(['/customer-product-view'], { queryParams: { _id: _id } })
  }
  BannerListArray: any = []
  getBannerProductList() {
    let apiReqUrl: any = `user/listBanner`
    let apiReqData: any = {
      // page: String(this.currentPage),
      limit: String(this.itemPerPage),
    }
    apiReqData = this.commonService.removeEmptyKey(apiReqData)
    this.commonService.postApi(apiReqUrl, apiReqData, 0).subscribe((res: any) => {

      if (res.responseCode == 200) {
        this.BannerListArray = res.result.docs ? res.result.docs : '';
        // this.datalist = res.result.docs[0].thumbnail
        this.totalItems = res.result.total


        // this.commonService.successToast(res['responseMessage'])
        this.commonService.hideSpinner();
        // this.commonService.successToast(res.responseMessage);
      } else {
        this.BannerListArray = []
        this.totalItems = 0
        this.commonService.hideSpinner();
        this.commonService.errorToast(res.responseMessage)
      }
    }, (err: any) => {
      if (err.responseCode == 404) {
        this.BannerListArray = []
        this.totalItems = 0
        // this.commonService.errorToast(err.responseMessage);

      }
    })
  }
  getCountDownOld(i){
    return this.countDown[i]
  }
  getCountDown(i){
    return this.countDownNew[i]
  }
  getPercentage(dealPrice,actualPrice){
    let minus = (actualPrice -dealPrice)
    let getDiscountP = (minus / actualPrice)
    let getPercent = (getDiscountP *100)
    return getPercent
  }
  onImageLaod(e){
    return e.isLoaded = true
   }
   onBannerLaod(i){
     this.isLoaded[i] = true
   }
   length(n){
    return new Array(n)
   }
   dealVIEwNew(_id:any) {
    sessionStorage.setItem('selectedService', JSON.stringify(_id))
    this.router.navigate(['/customer-service-deal-view-particular'])
  }
  ngOnDestroy(): void {
    console.log(this.serviceData);

    // this.stateManagement.categoryRetailer.next(this.categoryListArray)
    // this.stateManagement.getDealRetailer.next(this.serviceDetail)
    // this.stateManagement.getProductRetailer.next(this.bannerData)
    // this.stateManagement.getServiceRetailer.next(this.serviceData )
    // this.stateManagement.getserviceOnBookingRetailer.next(this.serviceDetailDeals)
  }
  getFixed(n : number){
    return n.toFixed(2) || 0
  }

}
