<div class="container  fw-b ">
    <app-linker-route [headingName]="headingName" [linkerRouteItems]="linkerRouteItems"></app-linker-route>
</div>

<div class="main-container">
    <div class="container">
        <div class="">
            <img src="assets/latest/hrline.svg" alt="">
        </div>
      <div class="d-flex justify-content-center">
        <div class="left-container">
            <div class="mp10 mt-4">
       
                <form [formGroup]="changPassword">
                    <div class="form-group">
                      <div class="input-control">
                        <label for="">Old Password</label>
                        <input [type]="changetype?'password':'text'" class="form-control" id="form-control" placeholder="Old password"
                        formControlName="oldpassword" maxlength="16"
                        (keypress)="preventSpace($event)" onselectstart="return false;" onpaste="return false;" oncopy="return false;"
                        oncut="return false" ondrag="return false" ondrop="return false" autocomplete="off">
                        <span class="eyeicon" (click)="viewpass()"><em
                            [ngClass]="visible?'fa fa-eye':'fa fa-eye-slash'"></em></span>
                    </div>
                      <div class="for-validation mb-4">
                        <div class="for-validation text-danger"
                        *ngIf="changPassword.get('oldpassword')?.hasError('required') && changPassword.get('oldpassword')?.touched">
                        *Old password
                        required.
                        </div>
                      </div>
                    </div>
                   <!-- new pass -->
                   <div class="form-group">
                    <div class="input-control">
                        <label for="">New Password</label>
                      <input [type]="changetypes?'password':'text'" class="form-control" id="form-control" placeholder="New password"
                      formControlName="password" maxlength="16" (keypress)="preventSpace($event)" onselectstart="return false;" onpaste="return false;" oncopy="return false;"
                      oncut="return false" ondrag="return false" ondrop="return false" autocomplete="off">
                      <span class="eyeicon" (click)="viewpas()"><em
                        [ngClass]="visibles?'fa fa-eye':'fa fa-eye-slash'"></em></span>
                  </div>
                    <div class="for-validation mb-4">
                        <div class="for-validation text-danger"
                        *ngIf="changPassword.get('password')?.hasError('required') && changPassword.get('password')?.touched">
                        *New Password is
                        required.</div>
                    </div>
                  </div>
                    <!-- new pass -->
                    <div class="form-group">
                        <div class="input-control">
                            <label for="">Confirm Password</label>
                          <input [type]="cengesestype?'password':'text'" class="form-control" id="form-control" placeholder="Confirm password"
                          formControlName="confirmPassword" maxlength="16" onselectstart="return false;" onpaste="return false;" oncopy="return false;"
                          oncut="return false" ondrag="return false" ondrop="return false" autocomplete="off">
                          <span class="eyeicon" (click)="viewpassd()"><em
                            [ngClass]="visibled?'fa fa-eye':'fa fa-eye-slash'"></em></span>
                      </div>
                        <div class="for-validation mb-4">
                            <div class="for-validation text-danger"
                            *ngIf="changPassword.get('confirmPassword')?.hasError('required') && changPassword.get('confirmPassword')?.touched ;else elseBlock">
                            Please enter valid confirm password</div>
                            <ng-template #elseBlock>
                              <div class="for-validation text-danger" 
                            *ngIf="(changPassword.get('password')?.value != changPassword.get('confirmPassword')?.value) &&  changPassword.get('confirmPassword')?.dirty">
                            Password and Confirm Password must be match.</div>
                            </ng-template>
                        </div>
                      </div>
                    <div class="d-flex justify-content-center" style="align-items: center;">
                      <div class="left-wrap w-100">
                          <button class="butwhite" (click)="navigateToProfile()"
                       type="submit">Back</button>
              
                        <button class="butRed" (click)="navigateTochangePassword()" type="submit"
                        [disabled]="!changPassword.valid">Update
                        </button>
                      </div>
                    </div>
                  </form>
            </div>
      
          </div>
          <!-- <div class="right-container">
            <div class="image-container">
              <img [src]="'assets/latest/change-pass.svg'" alt="Image" class="img-fluid">
            </div>
          </div> -->
      </div>
    </div>
  </div>
