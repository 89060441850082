<div class="container  fw-b ">
    <app-linker-route [headingName]="headingName" [linkerRouteItems]="linkerRouteItems"></app-linker-route>
</div>

<div class="main-container">
    <div class="container d-flex mp11">
      <div class="left-container">
        <div class="mp10">
          <div class="emailitem">
            <img src="assets/latest/emailimg.png" alt=""> <span class="emailaddres"> info@bazarhub.africa</span>
          </div>
            <form [formGroup]="helpForm">
                <div class="form-group">
                  <div class="input-control">
                    <input type="text" class="form-control subject" placeholder="Subject" type="text" formControlName="subject" maxlength="256" 
                      id="username">
                  </div>
                  <div class="for-validation mb-4">
                    <div class="for-validation text-danger"
                      *ngIf="helpForm.get('subject')?.hasError('required') && helpForm.get('subject')?.touched">
                      *Please enter subject.
                    </div>
                  </div>
                </div>
                <div class="form-group">
                  <div class="input-control">
                      <textarea type="text"  class="user-email" placeholder="Description" rows="10" formControlName='description'></textarea>
                  </div>
                  <div class="for-validation  mb-3 ">
                    <div class="for-validation text-danger"
                      *ngIf="helpForm.get('description')?.hasError('required') && helpForm.get('description')?.touched">
                      *Please enter description.</div>
              
                  </div>
                </div>
                <div class="d-flex justify-content-center" style="align-items: center;">
                  <div class="left-wrap">
                      <button class="butwhite" (click)="cancel()"
                   type="button">Cancel</button>
          
                    <button class="butRed" (click)="helpApi()"
                      [disabled]="!helpForm.valid" type="submit">Submit</button>
                  </div>
                </div>
              </form>
        </div>
  
      </div>
      <div class="right-container">
        <div class="image-container" style="margin-top: 0px;">
          <img [src]="'assets/latest/productEnq.svg'" alt="Image" class="img-fluid">
        </div>
      </div>
    </div>
  </div>




<!-- <auth-layout title="" subTitile="" sideImage="assets/latest/productEnq.svg">
   
 
</auth-layout> -->

