import { Component, OnDestroy, OnInit } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { CommonService } from 'src/app/provide/common.service';
import { StateManagementService } from 'src/app/provide/state-management.service';
declare var $;
@Component({
  selector: 'app-homepage',
  templateUrl: './homepage.component.html',
  styleUrls: ['./homepage.component.scss'],
})
export class HomepageComponent implements OnInit, OnDestroy {
  sliderConfig = {
    slidesToShow: 3,
    slidesToScroll: 1,
    arrows: true,
    dots: true,
    autoplay: true,
    autoplaySpeed: 2000,
    responsive: [
      {
        breakpoint: 768,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 1,
        },
      },
      {
        breakpoint: 576,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
        },
      },
    ],
  };
  homepage!: FormGroup;
  itemPerPage = 4;
  currentPage = 1;
  totalItems: any;
  termsData: any;
  categoryListArrayNew: any;
  _id: any;
  a: any;
  totalPage: number = 0;
  // latitude and longitude property
  latitude: any;
  longitude: any;
  ipAddress: string;
  serviceDetailDeals: any = new Array(1);
  // totalItems: any;
  // categoryListArray:[];
  // categoryListArray: never[];
  isLoaded: any = new Array(4);
  constructor(
    private activatedroute: ActivatedRoute,
    public commonService: CommonService,
    private router: Router,
    public stateManagement: StateManagementService
  ) {
    this.activatedroute.queryParams.subscribe((res: any) => {
      this._id = res._id;
    });

    if (localStorage.getItem('token')) {
      if (localStorage.getItem('userType') == 'RETAILER') {
        this.router.navigate(['/retailer-home']);
      }
    }
  }
  isLogin: any;

  ngOnInit(): void {
    this.commonService.profileUpdateItem.next(true);
    // this.getBannerProductList()
    window.scrollTo(0, 0);
    this.getGlobalData();
    // this.homePage();
    // this.getCategoryList();
    this.homepage = new FormGroup({
      email: new FormControl('', [
        Validators.required,
        Validators.pattern(
          /^([a-zA-Z0-9_\-\.]+)@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.)|(([a-zA-Z0-9\-]+\.)+))([a-zA-Z]{2,10}|[0-9]{1,3})(\]?)$/i
        ),
      ]),
    });
    this.isLogin = localStorage.getItem('token');

    // this.stateManagement.categoryCustomer.subscribe((res) => {
    //   if (res) {
    //     this.categoryListArray = res
    //   }
    //   else {
    //     this.getCategoryLists()

    //   }
    // })
    this.stateManagement.getProductCustomer.subscribe((res) => {
      if (res) {
        this.bannerData = res;
      } else {
        this.getLocation();
      }
    });
    this.stateManagement.getDealCustomer.subscribe((res) => {
      if (res) {
        this.serviceDetail = res;

        for (let index = 0; index < this.serviceDetail.length; index++) {
          this.getCoundown(this.serviceDetail[index]['dealEndTime'], index);
        }
      }
    });
    this.stateManagement.getserviceOnBooking.subscribe((res) => {
      if (res) {
        this.serviceDetailDeals = res;

        for (let index = 0; index < this.serviceDetailDeals.length; index++) {
          this.getCoundownNew(
            this.serviceDetailDeals[index]['dealEndTime'],
            index
          );
        }
      }
    });
    // this.stateManagement.getServiceCustomer.subscribe((res) => {
    //   if (res) {
    //     this.serviceData = res

    //   }
    //   else {
    //     this.getServiceProduct()

    //   }
    // })
    // this.getcontent();
    // this.getCategoryLists()
    this.getLocation();
    // this.getBanner()
    // this.getServiceProduct()
    navigator.geolocation.getCurrentPosition(
      function (position) {
        // alert('allow');
      },
      function () {
        alert(
          'You need to enable geolocation services in your browser to use this feature.'
        );
      }
    );
    this.getPosition().then((pos) => {
      localStorage.setItem('location', JSON.stringify(pos));
    });
    // this.getLocation();
  }

  getPosition(): Promise<any> {
    return new Promise((resolve, reject) => {
      navigator.geolocation.getCurrentPosition(
        (resp) => {
          resolve({ lng: resp.coords.longitude, lat: resp.coords.latitude });
        },
        (err) => {
          reject(err);
        }
      );
    });
  }

  getLocation() {
    if (navigator.geolocation) {
      navigator.geolocation.getCurrentPosition(
        (position) => {
          if (position) {
            this.latitude = position.coords.latitude;
            this.longitude = position.coords.longitude;

            console.log(this.longitude, 'longitutdeeeeee');
            // this.getBanner()
            // this.getproduct()
            this.getGlobalData();
            // this.getBannerBooking()
          }
        },
        (error) => console.log(error)
      );
    } else {
      alert('Geolocation is not supported by this browser.');
    }
  }

  preventSpace(event: any) {
    if ((event.charCode == 32 || event.charCode == 64) && !event.target.value) {
      event.preventDefault();
    }
  }

  loggedin() {
    return localStorage.getItem('token');
  }

  onLogout() {
    localStorage.removeItem('token');
  }
  // ========================

  getcontent() {
    this.commonService.getApi('user/homePageForWholeSaler', 1).subscribe(
      (res: any) => {
        this.commonService.showSpinner();

        if (res['responseCode'] == 200) {
          this.termsData = res['result']['dealDetails']['docs'];
          this.totalItems = res.result.total;

          this.commonService.hideSpinner();
          // this.commonService.successToast(res.responseMessage);
        } else {
          this.termsData = [];
          this.totalItems = 0;
          this.commonService.hideSpinner();
          this.commonService.errorToast(res.responseMessage);
        }
      },
      (err) => {
        if (err.responseCode == 404) {
          this.termsData = [];
          this.totalItems = 0;
          // this.commonService.warningToast(err['error']['responseMessage'])
        }
      }
    );
  }

  pagination(event: number) {
    this.currentPage = event;
    this.getcontent();
  }

  // ============categay list===========
  categoryListArray: any = new Array(10);

  // getCategoryLists() {

  //   let apiReqUrl: any = `admin/listCategory`
  //   // this.commonService.showSpinner();
  //   let apiReqData: any = {
  //     // page: String(this.currentPage),
  //     // limit: String(this.itemPerPage)
  //   }
  //   // remove empty keys from request body
  //   // apiReqData = this.commonService['removeEmptyKey'](apiReqData)
  //   apiReqData = this.commonService.removeEmptyKey(apiReqData)
  //   let a = this.commonService.isLoggedIn() ? 1 : 0
  //   this.commonService.postApi(apiReqUrl, apiReqData, a).subscribe((res: any) => {
  //     if (res.responseCode == 200) {
  //       this.categoryListArray = res.result.docs ? res.result.docs : '';
  //       this.totalItems = res.result.total
  //       this.commonService.hideSpinner();
  //       // this.commonService.successToast(res.responseMessage);
  //     } else {
  //       this.categoryListArray = []
  //       this.totalItems = 0
  //       this.commonService.hideSpinner();
  //       this.commonService.errorToast(res.responseMessage)
  //     }
  //   })
  // }

  getPrice(e: any) {
    let a = e.replace(/,/g, ' ');
    return a.replace(/R/g, 'R ');
  }

  forID(_id: any) {
    this.router.navigate(['/deal-details'], { queryParams: { _id: _id } });
  }

  // knoemore
  descrition: any;

  // ====================================================save button================================
  getDeals() {
    let data = {
      email: this.homepage.value.email,
      descrition: this.homepage.value.descrition,
      // dealDiscount: this.addDeals.value.discount,
      // dealStartTime: this.addDeals.value.dealStartTime,
      // dealEndTime: this.addDeals.value.dealEndTime,
      // dealsFor: "RETAILER"
    };

    let apiReqUrl: any = `user/stepsForUser`;
    this.commonService.showSpinner();
    this.commonService.postApi(apiReqUrl, data, 0).subscribe((res: any) => {
      if (res.responseCode == 200) {
        this.commonService.hideSpinner();
        this.commonService.successToast(res.responseMessage);
        this.homepage.reset();
        // this.router.navigate(['/list-deals'])
        // localStorage.setItem('token', res.result.token)
      } else {
        this.commonService.hideSpinner();
        this.commonService.errorToast(res.responseMessage);
      }
    });
  }
  viewParticularProducts(productId: any) {
    this.router.navigate(['/list-product'], {
      queryParams: { _id: productId },
    });
  }
  onSearch(id?: any) {
    // this.showSearchType = false;
    localStorage.setItem('category', id);
    this.router.navigate(['/search-product']);
    this.commonService.onFirstComponentButtonClick();
    // this.router.navigate(['/search-product'], { queryParams: { _id: id,search:search} })
  }
  bannerData: any = new Array(1);
  serviceDetail: any = new Array(1);
  // getBanner() {

  //   let url = `deal/listDealByLocation`
  //   // let url =`product/listProductBySortion`
  //   let data = {
  //     "dealType": "PRODUCT",
  //     "userType": this.getEndUrlSegment() == 'retailer-home' ? 'WHOLE_SALER' : "RETAILER",
  //     'lng': this.longitude,
  //     'lat': this.latitude,
  //     'page': this.currentPage,
  //     'limit': 10
  //   }

  //   this.commonService.removeEmptyKey(data)
  //   // this.commonService.showSpinner()
  //   let a = this.commonService.isLoggedIn() ? 1 : 0
  //   this.commonService.postApi(url, data, a).subscribe((res: any) => {
  //     if (res.responseCode == 200) {

  //       if (this.timerInterval) {
  //         clearInterval(this.timerInterval);
  //         this.countDown = [];
  //       }
  //       this.serviceDetail = res.result.docs
  //       this.totalItems = res.result.totalPages
  //       for (let index = 0; index < this.serviceDetail.length; index++) {
  //         this.getCoundown(this.serviceDetail[index]['dealEndTime'], index);
  //       }
  //       // this.dealData = res["result"]["docs"];
  //       this.commonService.hideSpinner()

  //       this.commonService.hideSpinner()
  //     }
  //     else{
  //       this.serviceDetail = []
  //     }
  //   },(err)=>{
  //     this.serviceDetail = []
  //   })
  // }
  // getBannerBooking() {

  //   let url = `deal/dealsListOnCategory`
  //   // let url =`product/listProductBySortion`
  //   // let data = {
  //   //   "dealType": "PRODUCT",
  //   //   "userType": "RETAILER",
  //   //   'lng': this.longitude,
  //   //   'lat': this.latitude,
  //   //   'page': this.currentPage,
  //   //   'limit': 10
  //   // }

  //   // this.commonService.removeEmptyKey(data)
  //   // this.commonService.showSpinner()
  //   let token = localStorage.getItem('token') ? 1 : 0
  //   this.commonService.getApi(url,token).subscribe((res: any) => {
  //     if (res.responseCode == 200) {

  //       if (this.timerInterval) {
  //         clearInterval(this.timerInterval);
  //         this.countDown = [];
  //       }
  //       this.serviceDetailDeals = res.result
  //       this.totalItems = res.result.totalPages
  //       for (let index = 0; index < this.serviceDetailDeals.length; index++) {
  //         this.getCoundownNew(this.serviceDetailDeals[index]['dealEndTime'], index);
  //       }
  //       // this.dealData = res["result"]["docs"];
  //       this.commonService.hideSpinner()

  //       this.commonService.hideSpinner()
  //     }
  //     else {
  //       this.serviceDetailDeals = []
  //     }
  //   }, (err: any) => {
  //     this.serviceDetailDeals = []
  //   })
  // }

  myTimer() {}
  // getDealDescription
  countDown: string[] = [];
  timerInterval: any = [];
  countDownNew: any = [];
  timerIntervalNew: any = [];
  new: any;
  getCoundown(date: Date, i: number) {
    var countDownDate = new Date(date).getTime();
    let me = this;
    // Update the count down every 1 second

    this.timerInterval[i] = setInterval(() => {
      if (true) {
        // Get today's date and time
        let now = new Date().getTime();

        // Find the distance between now and the count down date
        let distance = countDownDate - now;

        // Time calculations for days, hours, minutes and seconds
        let days = Math.floor(distance / (1000 * 60 * 60 * 24));
        let hours = Math.floor(
          (distance % (1000 * 60 * 60 * 24)) / (1000 * 60 * 60)
        );
        let minutes = Math.floor((distance % (1000 * 60 * 60)) / (1000 * 60));
        let seconds = Math.floor((distance % (1000 * 60)) / 1000);

        // Output the result in an element with id="demo"
        this.countDown[i] =
          days + 'D:' + hours + 'H:' + minutes + 'M:' + seconds + 'S';
        // If the count down is over, write some text
        if (distance < 0) {
          clearInterval(me.timerInterval);
          me.countDown[i] = 'EXPIRED';
        }
      }

      // me.countDown[i] = mm
    }, 1000);
  }
  getCountDownOld(i, date?: Date) {
    if (!this.countDown[i]) {
      this.getCoundown(date, i);
    }
    return this.countDown[i];
  }
  getCoundownNew(date: Date, i: number) {
    var countDownDate = new Date(date).getTime();
    let me = this;
    // Update the count down every 1 second

    this.timerIntervalNew[i] = setInterval(() => {
      if (true) {
        // Get today's date and time
        let now = new Date().getTime();

        // Find the distance between now and the count down date
        let distance = countDownDate - now;

        // Time calculations for days, hours, minutes and seconds
        let days = Math.floor(distance / (1000 * 60 * 60 * 24));
        let hours = Math.floor(
          (distance % (1000 * 60 * 60 * 24)) / (1000 * 60 * 60)
        );
        let minutes = Math.floor((distance % (1000 * 60 * 60)) / (1000 * 60));
        let seconds = Math.floor((distance % (1000 * 60)) / 1000);

        // Output the result in an element with id="demo"
        me.countDownNew[i] =
          days + 'D:' + hours + 'H:' + minutes + 'M:' + seconds + 'S'; // If the count down is over, write some text
        if (distance < 0) {
          clearInterval(me.timerIntervalNew);
          me.countDownNew[i] = 'EXPIRED';
        }
      }

      // me.countDown[i] = mm
    }, 1000);
  }
  getCountDown(i) {
    return this.countDownNew[i];
  }
  // getproduct() {
  //   let url = `product/listProductBySortion`
  //   let data = {
  //     // "dealType" :  "PRODUCT",
  //     "userType": this.getEndUrlSegment() == 'retailer-home' ? 'WHOLE_SALER' : "RETAILER",
  //     'lng': this.longitude,
  //     'lat': this.latitude,
  //     'page': this.currentPage,
  //     'limit': 10
  //   }
  //   let a = this.commonService.isLoggedIn() ? 1 : 0

  //   // this.commonService.showSpinner()
  //   this.commonService.postApi(url, data, a).subscribe((res: any) => {
  //     if (res.responseCode == 200) {
  //       this.bannerData = res["result"]["docs"];
  //       this.commonService.hideSpinner()

  //       this.commonService.hideSpinner()
  //     }
  //   })
  // }
  serviceData: any = new Array(1);
  // getServiceProduct() {
  //   let url = `service/listCategoryService`
  //   let data = {
  //     // "dealType" :  "PRODUCT",
  //     'page': this.currentPage,
  //     'limit': 10
  //   }

  //   let a = this.commonService.isLoggedIn() ? 1 : 0
  //   this.commonService.postApi(url, data, a).subscribe((res: any) => {
  //     if (res.responseCode == 200) {
  //       this.serviceData = res["result"]["docs"];

  //     }
  //   })
  // }
  // directPage(url) {
  //   if (url) {
  //     window.open(url, '_blank');
  //   }
  // }
  BannerListArray: any = [];

  // getBannerProductList() {
  //   let apiReqUrl: any = `user/listBanner`
  //   let apiReqData: any = {
  //     // page: String(this.currentPage),
  //     limit: String(this.itemPerPage),
  //   }
  //   apiReqData = this.commonService.removeEmptyKey(apiReqData)
  //   this.commonService.postApi(apiReqUrl, apiReqData, 0).subscribe((res: any) => {
  //     if (res.responseCode == 200) {
  //       this.BannerListArray = res.result.docs ? res.result.docs : '';
  //       this.totalItems = res.result.total
  //       this.commonService.hideSpinner();
  //     } else {
  //       this.BannerListArray = []
  //       this.totalItems = 0
  //       this.commonService.hideSpinner();
  //       this.commonService.errorToast(res.responseMessage)
  //     }
  //   }, (err: any) => {
  //     if (err.responseCode == 404) {
  //       this.BannerListArray = []
  //       this.totalItems = 0

  //     }
  //   })
  // }
  navigateToViewProduct(_id, q) {
    this.router.navigate(['/product-view-customer'], {
      queryParams: { _id: _id, q },
    });
  }
  navigateToViewService(_id, catName, desc) {
    this.router.navigate(['/customer-service-available'], {
      queryParams: { _id: _id, catName: catName, desc: desc },
    });
  }
  navigateToViewProducts(_id, categoryName) {
    this.router.navigate(['/customer-category-list-view'], {
      queryParams: { _id: _id, categoryName: categoryName },
    });
  }

  dealVIEw(_id, q) {
    this.router.navigate(['/customer-product-view'], {
      queryParams: { _id: _id, q },
    });
  }
  dealVIEwNew(_id, q) {
    sessionStorage.setItem('selectedService', JSON.stringify(_id));
    this.router.navigate(['/customer-service-deal-view-particular']);
  }

  onImageLaod(e) {
    return (e.isLoaded = true);
  }
  onBannerLaod(i) {
    this.isLoaded[i] = true;
  }
  getPercentage(dealPrice, actualPrice) {
    let minus = actualPrice - dealPrice;
    let getDiscountP = minus / actualPrice;
    let getPercent = getDiscountP * 100;
    return getPercent.toFixed(2);
  }
  length(n) {
    return new Array(n);
  }
  ngOnDestroy(): void {
    // this.stateManagement.categoryCustomer.next(this.categoryListArray)
    // this.stateManagement.getProductCustomer.next(this.bannerData)
    // this.stateManagement.getDealCustomer.next(this.serviceDetail)
    // this.stateManagement.getServiceCustomer.next(this.serviceData)
    // this.stateManagement.getserviceOnBooking.next(this.serviceDetailDeals)
  }
  getEndUrlSegment(): string {
    const url = this.router.url;
    const segments = url.split('/');
    const lastSegmentWithQueryParamsAndFragments =
      segments[segments.length - 1];
    const endUrlSegment = lastSegmentWithQueryParamsAndFragments
      .split('?')[0]
      .split('#')[0];
    return endUrlSegment;
  }
  getDealPrice(price, percentage): string {
    let dealPrice = Number(price) - Number(price) * Number(percentage / 100);
    return dealPrice.toFixed(2);
  }
  globalData: any;
  getGlobalData() {
    console.log(this.latitude, 'latitudeeeeeeee');
    let data = {
      dealType: 'PRODUCT',
      userType:
        this.getEndUrlSegment() == 'retailer-home' ? 'WHOLE_SALER' : 'RETAILER',
      lng: this.longitude,
      lat: this.latitude,
      page: this.currentPage,
      limit: 10,
    };
    let url = `user/homePageApi?dealType=${data.dealType}&userType=${data.userType}&lng=${data.lng}&lat=${data.lat}`;

    let a = this.commonService.isLoggedIn() ? 1 : 0;

    // this.commonService.showSpinner()
    this.commonService.getApi(url, a).subscribe((res: any) => {
      if (res.responseCode == 200) {
        this.globalData = res['result'];
        this.BannerListArray = res.result.banner ? res.result.banner : '';
        this.categoryListArray = res?.result?.categories;
        this.bannerData = res?.result?.products;
        console.log(this.bannerData, 'bannnerrrr');
        this.serviceDetail = res?.result?.deals_on_product;
        this.serviceData = res?.result?.booking_categories;
        this.serviceDetailDeals = res?.result?.dealsOnBooking;
        for (let index = 0; index < this.serviceDetailDeals.length; index++) {
          this.getCoundownNew(
            this.serviceDetailDeals[index]['dealEndTime'],
            index
          );
        }

        // this.commonService.hideSpinner()

        // this.commonService.hideSpinner()
      }
    });
  }
}
