<div class="container  fw-b ">
    <app-linker-route [headingName]="name" [linkerRouteItems]="linkerRouteItems"></app-linker-route>
</div>

<div class="container mt-2 mb-5 wrapper-content">
    <div class="">
        <div class="row col-12 d-flex mt-3 justify-content-end" *ngIf="!flag">

            <div class="col-3 d-flex justify-content-end ml-3 ">
                <button class="addBtn" (click)="navigateToAddDeals()">+ Deals</button>
            </div>
            <div class="col-3 ml-3">
                <button class="addBtn" (click)="modalStartCampaign()">Start campaign</button>
            </div>
        </div>

        <div class="row mt-3 mb-5">
            <div class="col-lg-12 col-md-12 col-sm-6">
                <div class="" style="margin-top: 1em;">
                    <h3>{{categoryName}}</h3>
                    <div id="accordion" *ngFor="let item of serviceDetailsArray;let i = index">
                        <mat-accordion>
                            <mat-expansion-panel (opened)="panelOpenState = true" (closed)="panelOpenState = false"
                                [disabled]="!item?.serviceArray.length">
                                <mat-expansion-panel-header class="faq-qsn">
                                  
                                    <div style="display: flex; flex-direction: row; flex:1;">
                                        <mat-panel-title class="gg" style="color:rgb(73, 116, 113) ;width:30%;">
                                            {{item?.subCategory?.subCategoryName || '---'}}
                                        </mat-panel-title>
                                        <!-- <mat-panel-title class="gg" style="color:rgb(73, 116, 113) ;font-size: 14px; width: 40px;padding-left: 45px;">
                                        Weight
                                        </mat-panel-title>
                                        <mat-panel-title class="gg" style="color:rgb(73, 116, 113) ;font-size: 14px; text-align: left; padding-left: 20px"> -->
                                        <!-- <mat-panel-title class="gg" style="color:rgb(73, 116, 113) ;font-size: 14px; width: 25px;padding-left: 50px;"> -->
                                        <mat-panel-title class="gg" style="color:rgb(73, 116, 113) ;font-size: 14px; width: 25px;padding-left: 50px;" *ngIf="item?.subCategory?.categoryId?.categoryType != 'SERVICE_PROVIDER' ">
                                        Weight
                                        </mat-panel-title>
                                        <mat-panel-title class="gg" style="color:rgb(73, 116, 113) ;font-size: 14px; padding-left: 80px;"  *ngIf="item?.subCategory?.categoryId?.categoryType != 'SERVICE_PROVIDER' ">
                                            Volume
                                        </mat-panel-title>
                                        
                                        <mat-panel-title class="gg" style="color:rgb(73, 116, 113); width: 20%;"  [style.visibility]="item?.subCategory?.subCategoryName?.toLowerCase().trim() != 'exobe delivery' ? 'hidden' : 'visible'">
                                            Delivery Type
                                        </mat-panel-title>
                                      
                                    </div>

                                </mat-expansion-panel-header>
                                <ng-container *ngIf=" item?.serviceArray.length">
                                    <ng-container>
                                        <!-- <div class="d-flex flex-direction-row  justify-content-between">
                                            <div class="gg" style="color:rgb(73, 116, 113); width: 40%;">Service</div>
                                            <div class="gg" style="color:rgb(73, 116, 113); width: 20%;">Weight</div>
                                            <div class="gg" style="color:rgb(73, 116, 113); width: 20%;">Volume</div>
                                            <div class="gg" style="color:rgb(73, 116, 113); width: 20%;">Price</div>
                                        </div> -->
                                        <div class="d-flex flex-direction-row  justify-content-between"
                                            *ngFor="let data of item?.serviceArray;let j = index"
                                            style="text-align: left;align-items: center; ">
                                            <!-- <div class="custom-control custom-checkbox my-1 mr-sm-2" style="padding: 0px !important; max-width: 500px; -->
                                            <div class="custom-control custom-checkbox my-1 mr-sm-2" style="padding: 0px !important; max-width: 395px;
    width: 40%;">
                                                <input type="checkbox" class="custom-control-input"
                                                    [(ngModel)]="data.isSelected"
                                                    [attr.id]="'customControlInline'+i + '' + j"
                                                    (input)="checkValidation($event.target.checked, data.price, data?.serviceDetails?._id)">
                                                <label class="custom-control-label"
                                                    [attr.for]="'customControlInline'+i+ '' + j">{{data?.serviceDetails?.serviceName}}</label>
                                            </div>
                                            <!-- <div style="width: 80px;"> -->
                                              <!-- <div style="width: 15%; text-align: left;"  *ngIf="item?.subCategory?.categoryId.categoryType == 'PICKUP_PARTNER' || item?.subCategory?.categoryId.categoryType == 'FIELD_ENTITY'"
                                              >
                                                {{data?.serviceDetails?.weight || "--"}}
                                            </div>
                                            <div style="width: 15%;text-align: left;"  *ngIf="item?.subCategory?.categoryId.categoryType == 'PICKUP_PARTNER' || item?.subCategory?.categoryId.categoryType == 'FIELD_ENTITY'"
                                            >
                                                {{ data?.serviceDetails?.volume || "--"}}
                                            </div>
                                                <div style="width: 15%; text-align: left;" *ngIf="item?.subCategory?.subCategoryName?.toLowerCase().trim() == 'exobe delivery'"> -->
                                              <!-- <div style="width: 80px; text-align: left; margin-left: 20px;"> -->
                                            <!-- <div style="width: 80px; text-align: left; margin-left: 40px;"> -->
                                            <div style="width: 80px; text-align: left; margin-left: 40px;"  *ngIf="item?.subCategory?.categoryId?.categoryType != 'SERVICE_PROVIDER' ">
                                                <!-- <span style="color:rgb(73, 116, 113);">Weight :</span> -->
                                                {{data?.serviceDetails?.weight || "--"}}
                                            </div>
                                            <div style="width: 80px;text-align: left; margin-left: 75px;"  *ngIf="item?.subCategory?.categoryId?.categoryType != 'SERVICE_PROVIDER' ">
                                                <!-- <span style="color:rgb(73, 116, 113);">Volume :</span> -->
                                                {{ data?.serviceDetails?.volume || "--"}}
                                            </div>
                                                <div style="width: 180px; text-align: left; margin-left: 25px;"  [style.visibility]="item?.subCategory?.subCategoryName?.toLowerCase().trim() != 'exobe delivery' ? 'hidden' : 'visible'">
                                                    <!-- <span style="color:rgb(73, 116, 113);">Weight :</span> -->
                                                    <!-- {{data?.serviceDetails?.deliveryType || "--"}} -->
                                                    {{ getDeliveryDisplay(data?.serviceDetails?.deliveryType) || "--"}}
                                                </div>
                                            <div>
                                                <p class="mb-0 ner">R <input type="number" class="inpPrice"
                                                        (keypress)="commonService.numberOnly($event)"
                                                        [(ngModel)]="data.price" placeholder="Amount"
                                                        (input)="onAmountInput(data)"
                                                        [value]="data.price"> /unit</p>

                                                <!-- <p class="mb-0 !important ner">R <input type="number" class="inpPrice"
                                                        (keypress)="commonService.numberOnly($event)"
                                                        [(ngModel)]="data.price" placeholder="Amount"
                                                        [value]="data.price">
                                                    /unit
                                                </p> -->
                                            </div>
                                        </div>
                                    </ng-container>

                                    <!-- <ng-container *ngIf="item?.subCategory.categoryEnum == 'FIELD'">
                                        <table class="table">
                                            <thead>
                                                <tr>
                                                    <th></th>
                                                    <th>Daily</th>
                                                    <th>Weekly</th>
                                                    <th>Monthly</th>
                                                    <th>Quarterly</th>
                                                </tr>
                                            </thead>
                                            <tbody>
                                                <tr *ngFor="let data of item?.serviceArray;let j = index">
                                                    <td>
                                                        <div class="custom-control custom-checkbox my-1 mr-sm-2"
                                                            style="padding: 0px !important;">
                                                            <input type="checkbox" class="custom-control-input"
                                                                [(ngModel)]="data.isSelected"
                                                                [attr.id]="'customControlInline'+i + '' + j"
                                                                (input)="checkValidation($event.target.checked,data.price, data?.serviceDetails?._id)">
                                                            <label class="custom-control-label"
                                                                [attr.for]="'customControlInline'+i+ '' + j">{{data?.serviceDetails?.serviceName}}</label>
                                                        </div>
                                                    </td>

                                                    <td>
                                                        <p class="mb-0 !important ner">R <input type="number"
                                                                class="inpPrice"
                                                                (keypress)="commonService.numberOnly($event)"
                                                                [(ngModel)]="data.price_D" placeholder="Amount"
                                                                [value]="data.price_D">

                                                        </p>
                                                    </td>
                                                    <td>
                                                        <p class="mb-0 !important ner">R <input type="number"
                                                                class="inpPrice"
                                                                (keypress)="commonService.numberOnly($event)"
                                                                [(ngModel)]="data.price_W" placeholder="Amount"
                                                                [value]="data.price_W">

                                                        </p>
                                                    </td>
                                                    <td>
                                                        <p class="mb-0 !important ner">R <input type="number"
                                                                class="inpPrice"
                                                                (keypress)="commonService.numberOnly($event)"
                                                                [(ngModel)]="data.price_M" placeholder="Amount"
                                                                [value]="data.price_M">

                                                        </p>
                                                    </td>
                                                    <td>
                                                        <p class="mb-0 !important ner">R <input type="number"
                                                                class="inpPrice"
                                                                (keypress)="commonService.numberOnly($event)"
                                                                [(ngModel)]="data.price_Q" placeholder="Amount"
                                                                [value]="data.price_Q">

                                                        </p>
                                                    </td>

                                                </tr>
                                            </tbody>
                                        </table>

                                    </ng-container> -->

                                </ng-container>
                                <ng-container *ngIf="!item?.serviceArray.length">
                                    <p class="text-center">No data found</p>
                                </ng-container>

                            </mat-expansion-panel>
                        </mat-accordion>
                    </div>
                </div>
                <div class="mt-4">
                    <div class="right-totalamout">
                        <!-- <span><button class="butCh" (click)="navigateToserviceAvailableAdd()">Update</button></span> -->
                        <span><button class="butCh" (click)="update()">Update</button></span>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>
<div class="modal fade" id="startModalCampaign" tabindex="-1" role="dialog" aria-labelledby="exampleModalLabel"
    aria-hidden="true">
    <div class="modal-dialog" role="document">
        <div class="modal-content">
            <div class="modal-header" style="height: 90px; background: rgb(73, 116, 113);">
                <h5 class="modal-title" id="exampleModalLabel" style="width: 135px; height:60px"><img
                        style="height: 100%; width:100%; object-fit: cover;" src="assets/latest/exobe-logo.png" alt=""></h5>
                <button type="button" class="close" data-dismiss="modal" aria-label="Close">
                    <!-- <span aria-hidden="true" style="color: white;">&times;</span> -->
                </button>
            </div>
            <div class="modal-body">
                <div class="form">

                    <div class="row justify-content-between">
                        <div class="col-md-6 text-center">
                            <div class="form-group">
                                <label for="exampleInputEmail1">From Date</label>
                                <input type="datetime-local" class="form-control" [(ngModel)]="fromDate"
                                    aria-describedby="fromDate" placeholder="Enter From Date">

                            </div>
                        </div>
                        <div class="col-md-6 text-center">
                            <div class="form-group">
                                <label for="exampleInputEmail1">To Date</label>
                                <input type="datetime-local" class="form-control" [disabled]="!fromDate"
                                    [min]="fromDate" [(ngModel)]="toDate" aria-describedby="toDate"
                                    placeholder="Enter To Date">

                            </div>
                        </div>
                    </div>



                    <div class="row">

                        <div class="col-md-12 mb-4">
                            <label for="exampleInputEmail1">Select Service Category</label>
                            <select class="form-control sel-2" [(ngModel)]="_categoryId"
                                (change)="getSubCatList($event.target.value)">
                                <option value="">Select category</option>
                                <option *ngFor="let subCategory of newObj" [value]="subCategory?.subCategory?._id">
                                    {{ subCategory?.subCategory?.subCategoryName}}

                                </option>
                            </select>
                        </div>

                    </div>
                    <div class="row">

                        <div class="col-md-12 mb-4">
                            <label for="exampleInputEmail1">Select Service</label>
                            <select class="form-control sel-2" [(ngModel)]="_serviceId"
                                (change)="getPriceService($event.target.value)">
                                <option value="">Select Services</option>
                                <ng-container *ngFor="let subCategory of subServiceList.serviceArray">
                                    <option *ngIf="subCategory?.isSelected" [value]="subCategory?.catalogueId">
                                        {{ subCategory?.serviceDetails?.serviceName}}

                                    </option>
                                </ng-container>

                            </select>
                        </div>

                    </div>
                    <div class="row">
                        <div class="col-md-12 form-group">
                            <label for="exampleInputEmail1">Actual Price</label>
                            <input type="text" class="form-control" [(ngModel)]="servicePrice" readonly
                                aria-describedby="toDate" placeholder="Enter Price"
                                (keypress)="commonService.numberOnly($event)"
                                (keypress)="commonService.preventSpace($event);commonService.numberOnly($event)">
                        </div>
                    </div>
                    <div class="row">
                        <div class="col-md-12 form-group">
                            <label for="exampleInputEmail1">Discounted Price</label>
                            <input type="text" class="form-control" [(ngModel)]="_discountedPrice"
                                aria-describedby="toDate" placeholder="Enter Discounted Price"
                                (keypress)="commonService.numberOnly($event)"
                                (keypress)="commonService.preventSpace($event);commonService.numberOnly($event)">
                        </div>

                        <div *ngIf="servicePrice < _discountedPrice ">
                            <span style="color: rgb(73, 116, 113);">Invalid Price</span>

                        </div>
                    </div>

                    <div class="col-md-12 text-center mt -3">
                        <button class="btn btn-theme" (click)="startCampaign()">Confirm and Start Compaign</button>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>
