<auth-layout [title]="signupTitle" type="SIGNUP" subTitile="Access the Bazarhub using your email and password" sideImage="assets/latest/service-provider-signup.png">

    <form [formGroup]="businessform">
        <div class="d-flex justify-content-between">
            <div class="w-48">
                <label for="datepicker" class="for-label">First Name<span class="yy">*</span></label>
                <input type="text" class="form-control" formControlName="fName" (keypress)="preventSpace($event)"
                    maxlength="32" placeholder="Enter First Name">

                <div class="for-validation mb-4">
                    <div class="for-validation text-danger"
                        *ngIf="businessform.get('fName')?.hasError('required') && isSubmitted ">
                        Please enter first name.</div>

                    <div class="for-validation text-danger"
                        *ngIf="businessform.get('fName')?.hasError('minlength') && businessform.get('fName')?.touched ">
                        *Please enter minimum 2 character.</div>
                </div>
            </div>
            <div class="w-50">
                <label for="datepicker" class="for-label">Last Name<span class="yy">*</span></label>
                <input type="text" class="form-control" formControlName="lName" (keypress)="preventSpace($event)"
                    maxlength="32" placeholder=" Enter Last Name">
                <div class="for-validation mb-4">
                    <div class="for-validation text-danger"
                        *ngIf="businessform.get('lName')?.hasError('required')  && isSubmitted ">
                        Please enter last name.</div>

                    <div class="for-validation text-danger"
                        *ngIf="businessform.get('lName')?.hasError('minlength') && businessform.get('lName')?.touched ">
                        *Please enter minimum 2 character.</div>
                </div>
            </div>

        </div>

        <div class="d-flex justify-content-between">
            <div class="w-100">
                <label for="datepicker" class="for-label">Email Address<span class="yy">*</span></label>
                <input type="text" class="form-control" formControlName="email" (keypress)="preventSpace($event)"
                    maxlength="250" placeholder="Enter Email Address">
                <div class="for-validation mb-4">
                    <div class="for-validation text-danger"
                        *ngIf="businessform.get('email')?.hasError('required')  && isSubmitted">
                        *Email is required.</div>
                    <div class="for-validation text-danger"
                        *ngIf="businessform.get('email')?.hasError('pattern') && businessform.get('email')?.touched ">
                        *Please
                        enter a valid email</div>
                </div>

            </div>

        </div>

        <ng-container >
            <p class="owner mt-4">Business Details</p>
            <div class="d-flex justify-content-between">
                <div class="w-48">
                    <label for="datepicker" class="for-label">Company Name<span class="yy">*</span></label>
                    <input type="text" class="form-control" formControlName="company" (keypress)="preventSpace($event)"
                        placeholder=" Enter Company Name">
                    <div class="for-validation mb-4">
                        <div class="for-validation text-danger"
                            *ngIf="businessform.get('company')?.hasError('required')  && isSubmitted">
                            *Company name is required.</div>
                    </div>
                </div>
                <div class="w-50">
                    <label for="datepicker" class="for-label">Business Registration Number<span
                            class="yy">*</span></label>
                    <input type="text" class="form-control" formControlName="businessRegNumber" maxlength="17"
                        (keypress)="preventSpace($event)" placeholder=" Enter Business Registration Number">
                    <div class="for-validation mb-4">
                        <div class="for-validation text-danger"
                            *ngIf="businessform.get('businessRegNumber')?.hasError('required')  && isSubmitted ">
                            *Registration number is required.</div>
                    </div>
                </div>

            </div>

            <div class="d-flex justify-content-between">

                <div class="w-48">
                    <label for="datepicker" class="for-label">Monthly Revenue<span class="yy">*</span></label>

                    <select class="form-control sel-2" formControlName="monthlyRevenue">
                        <option value="">Select Monthly Revenue</option>
                        <option value="Less than R20k">Less than R20k</option>
                        <option value="R20k to R50k monthly">R20k - R50k</option>
                        <option value="R20k to R50k monthly">R20k - R50k</option>
                        <option value="More than R500k monthly">More than R500k</option>

                    </select>

                </div>
                <div class="w-50">
                    <label for="datepicker" class="for-label">Website’s URL ( Optional )
                    </label>
                    <input type="text" class="form-control" formControlName="url" (keypress)="preventSpace($event)"
                        placeholder=" Enter The Url">
                    <div class="for-validation mb-4">
                        <div class="for-validation text-danger"
                            *ngIf="businessform.get('url')?.hasError('pattern') && businessform.get('url')?.touched">
                            * Please enter valid url required.
                        </div>
                    </div>
                </div>

            </div>


            <div class="d-flex justify-content-between">

                <div class="w-100">
                    <div class="d-flex">
                        <p class="textt">Are you VAT registered?
                        </p>

                    </div>



                    <div class="d-flex justify-content-between">
                        <div class="col p-0">
                            <div class="form-check form-check-inline">
                                <input (click)="open()" class="form-check-input" type="radio" name="vat" id="" value="true"
                                    placeholder="Enter VAT Number" >
                                <label class="form-check-label" for="radios">Yes

                                </label>
                            </div>


                            <div class="form-check form-check-inline">
                                <input (click)="close()" class="form-check-input" type="radio" name="vat" checked id=""
                                    value="false" placeholder="Enter VAT Number" >
                                <label class="form-check-label">No</label>

                            </div>

                            <div class="hhh" *ngIf="showInput">
                                <input type="text" class="form-control" formControlName="vatNumberInput"
                                    (keypress)="preventSpace($event)" maxlength="15" placeholder="Enter VAT Number">

                                <div class="for-validation mb-4">
                                    <div class="for-validation text-danger"
                                        *ngIf="businessform.get('vatNumberInput')?.hasError('required')  && isSubmitted ">
                                        *VAT registered number is required.</div>
                                </div>
                            </div>

                        </div>

                    </div>
                </div>



            </div>
        </ng-container>

 


        <p class="owner mt-3">Business Banking Details</p>


        <div class="d-flex justify-content-between">
            <div class="w-48">
                <label for="datepicker" class="for-label">Bank Name<span class="yy">*</span></label>
                <input type="text" class="form-control" formControlName="bankname" (keypress)="preventSpace($event)"
                    placeholder="Enter Bank Name">


                <div class="for-validation mb-4">
                    <div class="for-validation text-danger"
                        *ngIf="businessform.get('bankname')?.hasError('required')  && isSubmitted ">
                        *Please enter bank name.</div>
                    <div class="for-validation text-danger"
                        *ngIf="businessform.get('bankname')?.hasError('pattern') && businessform.get('bankname')?.touched ">
                        *Invalid bank name.</div>
                </div>
            </div>
            <div class="w-50">
                <label for="datepicker" class="for-label">Branch Name<span class="yy">*</span></label>
                <input type="text" class="form-control" formControlName="branchname" (keypress)="preventSpace($event)"
                    placeholder="Enter Branch Name">

                <div class="for-validation mb-4">
                    <div class="for-validation text-danger"
                        *ngIf="businessform.get('branchname')?.hasError('required')  && isSubmitted">
                        *Branch name is required.</div>
                </div>

            </div>

        </div>

        <div class="d-flex justify-content-between">
            <div class="w-48">
                <label for="datepicker" class="for-label">SWIFT Code<span class="yy">*</span></label>
                <input type="text" class="form-control" formControlName="swiftcode" maxlength="11"
                    (keypress)="preventSpace($event)" placeholder=" Enter Swift Code">
                <div class="for-validation mb-4">
                    <div class="for-validation text-danger"
                        *ngIf="businessform.get('swiftcode')?.hasError('required')  && isSubmitted ">
                        *Swiftcode Required.</div>

                    <!-- <div class="for-validation text-danger"
                        *ngIf="businessform.get('swiftcode')?.hasError('pattern') && businessform.get('swiftcode')?.touched">
                        *Swiftcode should be alphanumeric.
                    </div> -->
                </div>

            </div>
            <div class="w-50">
                <label for="datepicker" class="for-label">Account Type<span class="yy">*</span></label>
                <!-- <input type="text" class="form-control" formControlName="accountType"
                                (keypress)="preventSpace($event)" placeholder="  Enter account type"> -->

                <select class="form-control sel-2" formControlName="accountType" placeholder="  Enter account type">
                    <option value="">Account type</option>
                    <option value="Savings Account">Savings Account</option>
                    <option value="Current Account">Current Account</option>
                    <option value="Business Account">Business Account</option>
                    <option value="Cheque Account">Cheque Account</option>
                    <option value="Transmission Account">Transmission Account</option>

                </select>
                <div class="for-validation mb-4">
                    <div class="for-validation text-danger"
                        *ngIf="businessform.get('accountType')?.hasError('required')&& isSubmitted">
                        * Enter youre account type.</div>
                </div>
            </div>

        </div>
        <div class="d-flex justify-content-between">
            <div class="w-48">
                <label for="datepicker" class="for-label">Account Name<span class="yy">*</span></label>
                <input type="text" class="form-control" formControlName="accountName" (keypress)="preventSpace($event)"
                    placeholder="  Enter Account Name">
                <div class="for-validation mb-4">
                    <div class="for-validation text-danger"
                        *ngIf="businessform.get('accountName')?.hasError('required') && isSubmitted ">
                        * Enter youre account name.</div>
                </div>
            </div>



            <div class="w-50">
                <label for="datepicker" class="for-label">Account Number<span class="yy">*</span></label>
                <input type="text" class="form-control"
                    (keypress)="preventSpace($event);restrictChar($event);NumOnly($event)"
                    formControlName="accountNumber" placeholder="Enter Account Number" maxlength="30">

                <div class="for-validation mb-4">
                    <div class="for-validation text-danger"
                        *ngIf="businessform.get('accountNumber')?.hasError('required') && isSubmitted ">
                        * Enter youre account number.</div>
                </div>
            </div>

        </div>

        <!-- old line -->
    </form>
    <div class="btn d-flex justify-content-center mt-4">

        <button type="button"  class="btn btny" *ngIf="!loading" (click)="businessDetaile()">SUBMIT
            <!-- [disabled]="!businessform.valid" -->
        </button>

        <button type="button"  class="btn btny op05" *ngIf="loading" >Loading...
            <!-- [disabled]="!businessform.valid" -->
        </button>

    
    </div>
</auth-layout>